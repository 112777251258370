import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BecPicProgramList from '@posts/articles/_bec-pic-program-list/index.mdx';
export const _frontmatter = {
  "title": "robot article navigation",
  "slug": "/articles/robot-article-navigation",
  "private": true,
  "description": "this page is not linked to like a URL, but rather using a javascript import"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <BecPicProgramList mdxType="BecPicProgramList" />
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/robots"
        }}>{`Page 1`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/robots/page-2"
        }}>{`Page 2`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/robots/page-3"
        }}>{`Page 3`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/robots/page-4"
        }}>{`Page 4`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/robots/page-5"
        }}>{`Page 5`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/robots/page-6"
        }}>{`Page 6`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/robots/page-7"
        }}>{`Page 7`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/robots/page-8"
        }}>{`Page 8`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/robots/page-9"
        }}>{`Page 9`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/robots/page-10"
        }}>{`Page 10`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/robots/page-11"
        }}>{`Page 11`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/robots/page-12"
        }}>{`Page 12`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      