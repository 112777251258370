import React from 'react'
import { Box, Card } from 'theme-ui'
import Name from './Product.Name'
import Price from './Product.Price'
import Description from './Product.Description'
import Media from './Product.Media'
import rv from '@components/utils/buildResponsiveVariant'
import PropTypes from 'prop-types'

const styles = {
  card: {
    width: `250px`,
    ml: `.5rem`,
    mb: `.5rem`,
    textDecoration: `inherit`,
    color: `inherit`
  },
  container: {
    // position: `relative`,
    // display: `block`,
    p: 4,
    backgroundColor: `#E9FFE1`,
    borderRadius: 15,
    height: `100%`,
    display: `flex`,
    flexDirection: `column`,
    justifyContent: `flex-start`,
    alignItems: 'center',
    flex: 1
  },
  body: {}
}

const ProductCard = ({
  variant,
  name,
  category,
  description,
  price_aud,
  price_usd,
  price_aud_original,
  url,
  ...props
}) => {
  return (
    <Card sx={{ ...styles.card, variant: rv(variant, 'card') }}>
      <Box sx={styles.container}>
        <Media {...props} slug={url} />
        <Box sx={styles.body}>
          <Name name={name} category={category} slug={url} />
          <Box sx={{ textAlign: 'right', width: '100%' }}>
            <Price
              price_aud={price_aud ?? price_aud_original ?? null}
              price_usd={price_usd ?? null}
              {...props.priceProps}
            />
          </Box>
          <Description description={description} />
        </Box>
      </Box>
    </Card>
  )
}

ProductCard.propTypes = {
  name: PropTypes.string.isRequired,
  variant: PropTypes.string,
  category: PropTypes.string,
  description: PropTypes.string,
  price_aud: PropTypes.string,
  price_usd: PropTypes.string,
  price_aud_original: PropTypes.string,
  url: PropTypes.string,
  priceProps: PropTypes.shape({})
}

export default ProductCard
