import React from 'react'
import { Text, Box, Heading } from 'theme-ui'
import rv from '@components/utils/buildResponsiveVariant'
import { Link } from 'gatsby'

const styles = {
  container: {
    mt: 3,
    mb: 3
  },
  heading: {
    mb: 0,
    color: `alpha`,
    textAlign: 'center'
  },
  category: {
    color: `omegaDark`,
    textAlign: 'center'
  }
}

const ProductName = ({ name, slug, category, variant }) => (
  <Box sx={styles.container}>
    <Heading
      variant={'h3'}
      as={Link}
      to={slug}
      sx={{ variant: rv(variant, 'title'), ...styles.heading }}
    >
      {name}
    </Heading>
    <Text sx={styles.category} variant={'xsmall'}>
      {category}
    </Text>
  </Box>
)

export default ProductName
