import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Box } from 'theme-ui'
import imageSearch from './imageSearch'
import ProductCard from '../Card'
import Slider from './Slider'
import JSONData from '@posts/articles/all-kits-with-pics/all-kits.json'
import _ from 'lodash'

const styles = {
  cards: {
    display: `flex`,
    flexWrap: `wrap`,
    justifyContent: `flex-start`
  }
}

const mapProducts = (products, data, variant) => {
  let productImg = []
  let image = null

  return (
    <>
      {products.map(product => {
        if (product.image) {
          productImg = product.image.split('/')
          productImg = productImg[productImg.length - 1].split('.')
          // const productImageFilename = _.head(
          //   _.split(_.last(_.split(product.image, '/')), '.')
          // )
          image = imageSearch(productImg[0], data)
        }

        return (
          <ProductCard
            key={product.name}
            imageObject={image}
            variant={variant}
            {...product}
          />
        )
      })}
    </>
  )
}

const Cards = ({ columns, onMouseOver, products, type, ...props }) => {
  const actualProducts = products.map(product => {
    // We allow the article creator to give us a 'key', which is one
    // of the key identifiers in the list of data, and we find the LAST item based on that.
    const foundProduct = JSONData.content.filter(item => {
      return (
        product.key === item.slug ||
        product.key === item.url ||
        product.key === item.name ||
        product.key === item.sku ||
        product.key === item.image
      )
    })

    // we only return the first found product in teh database.
    return _.head(foundProduct)
  })

  const data = useStaticQuery(graphql`
    {
      allFile(filter: { extension: { regex: "/(jpg)|(jpeg)|(png)|(gif)/" } }) {
        nodes {
          name
          extension
          childrenImageSharp {
            gatsbyImageData
          }
          relativePath
          relativeDirectory
          publicURL
          base
        }
      }
    }
  `)

  return type === 'slider' ? (
    <Slider products={actualProducts} variant={props.variant} data={data} />
  ) : (
    <Box sx={styles.cards}>
      {mapProducts(actualProducts, data, props.variant)}
    </Box>
  )
}

export default Cards
