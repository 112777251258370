import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import SurfaceMountMarkings3Digit from './_surface-mount-markings-3-digit.mdx';
import SurfaceMountMarkings4Digit from './_surface-mount-markings-4-digit.mdx';
export const _frontmatter = {
  "title": "Surface Mount Resistors",
  "slug": "/articles/chip-data-ebook-1d/surface-mount-resistors",
  "category": "Articles",
  "tags": [],
  "date": null,
  "thumbnail": null,
  "thumbnailText": "TODO",
  "originalUrl": "http://www.talkingelectronics.com/ChipDataEbook-1d/html/SM-Resistors.html",
  "author": "Colin Mitchell",
  "private": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><img parentName="p" {...{
        "src": "images/SMD's.jpg",
        "alt": null
      }}></img></p>
    <p><img parentName="p" {...{
        "src": "images/SMresistorsOnPCB.jpg",
        "alt": null
      }}></img><br parentName="p"></br>{`
`}<strong parentName="p">{`3-digit Surface Mount resistors on a PC board`}<br parentName="strong"></br>
        <img parentName="strong" {...{
          "src": "images/SMresistorsOnPCB-2.jpg",
          "alt": null
        }}></img><br parentName="strong"></br>
        {`4-digit Surface Mount resistors on a PC board`}</strong></p>
    <p>{`The photo above shows surface mount resistors on a circuit board. The components that are not marked are capacitors (capacitors are NEVER marked).`}<br parentName="p"></br>{`
`}{`All the SM resistors in the above photos conform to a 3-digit or 4-digit code. But there are a number of codes, and the 4-digit code caters for high tolerance resistors, so it’s getting very complicated.`}<br parentName="p"></br>{`
`}{`Here is a basic 3-digit SM resistor:`}</p>
    <p><img parentName="p" {...{
        "src": "/0d4bb4be2d8b0b47da8497c5b1c74457/chip-res-334-sm.gif",
        "alt": null,
        "title": "A 330k SM resistor"
      }}></img><br parentName="p"></br>{`
`}<em parentName="p">{`A 330k SM resistor`}</em></p>
    <p>{`The first two digits represent the two digits in the answer. The third digit represents the number of zero’s you must place after the two digits. The answer will be OHMS. For example: 334 is written 33 0 000. This is written 330,000 ohms. The comma can be replaced by the letter “k”. The final answer is: 330k.`}<br parentName="p"></br>{`
`}{`222 = 22 00 = 2,200 = 2k2`}<br parentName="p"></br>{`
`}{`473 = 47 000 = 47,000 = 47k`}<br parentName="p"></br>{`
`}{`474 = 47 0000 = 470,000 = 470k`}<br parentName="p"></br>{`
`}{`105 = 10 00000 = 1,000,000 = 1M = one million ohms`}<br parentName="p"></br>{`
`}{`There is one trick you have to remember. Resistances less than 100 ohms are written: 100, 220, 470. These are 10 and NO zero’s = 10 ohms = 10R`}<br parentName="p"></br>{`
`}{`or 22 and no zero’s = 22R or 47 and no zero’s = 47R.`}<br parentName="p"></br>{`
`}{`Sometimes the resistor is marked: 10, 22 and 47 to prevent a mistake.`}</p>
    <p><strong parentName="p">{`Remember`}</strong>{`:`}<br parentName="p"></br>{`
`}{`R = ohms`}<br parentName="p"></br>{`
`}{`k = kilo ohms = 1,000 ohms`}<br parentName="p"></br>{`
`}{`M = Meg = 1,000,000 ohms`}<br parentName="p"></br>{`
`}{`The 3 letters (R, k and M) are put in place of the decimal point. This way you cannot make a mistake when reading a value of resistance.`}</p>
    <h3 {...{
      "id": "surface-mount-current-sensing-resistors"
    }}>{`Surface Mount `}<strong parentName="h3">{`CURRENT SENSING`}</strong>{` Resistors`}</h3>
    <p>{`Many new types of CURRENT SENSING surface-mount resistors are appearing on the market and these are creating lots of new problems.`}<br parentName="p"></br>{`
`}{`Fortunately all resistors are marked with the value of resistance and these resistors are identified in MILLIOHMS. A miili ohm is one thousandth or an ohm and is written 0.001 when writing a normal mathematical number.`}<br parentName="p"></br>{`
`}{`When written on a surface mount resistor, the letter R indicates the decimal point and it also signifies the word “OHM” or “OHMS” and one milli-ohm is written R001`}<br parentName="p"></br>{`
`}{`Five miiliohms is R005 and one hundred milliohms is R100`}<br parentName="p"></br>{`
`}{`Some surface mount resistors have the letter “M” after the value to indicate the resistor has a rating of 1 watt. e.g: R100M These surface-mount resistors are specially-made to withstand a high temperature and a surface-mount resistor of the same size is normally 250mW or less.`}<br parentName="p"></br>{`
`}{`These current-sensing resistors can get extremely hot and the PC board can become burnt or damaged.`}<br parentName="p"></br>{`
`}{`When designing a PC board, make the lands very large to dissipate the heat.`}<br parentName="p"></br>{`
`}{`Normally a current sensing resistor is below one ohm (1R0) and it is easy to identify them as R100 etc.`}<br parentName="p"></br>{`
`}{`You cannot measure the value of a current sensing resistor as the leads of a multimeter have a higher resistance than the resistor and few multimeters can read values below one ohm.`}<br parentName="p"></br>{`
`}{`If the value is not visible, you will have to refer to the circuit.`}<br parentName="p"></br>{`
`}{`Before replacing it, work out why it failed.`}<br parentName="p"></br>{`
`}{`Generally it gets too hot. Use a larger size and add tiny heatsinks on each end.`}<br parentName="p"></br>{`
`}{`Here are some surface=mount current-sense resistors:`}</p>
    <p><img parentName="p" {...{
        "src": "images/SM-CurrentSensing.jpg",
        "alt": null
      }}></img></p>
    <h2 {...{
      "id": "the-complete-range-of-sm-resistor-markings"
    }}>{`THE COMPLETE RANGE OF SM RESISTOR MARKINGS`}</h2>

    <SurfaceMountMarkings3Digit mdxType="SurfaceMountMarkings3Digit" />

    <SurfaceMountMarkings4Digit mdxType="SurfaceMountMarkings4Digit" />
    <p>{`0000 is a value on a surface-mount resistor. It is a zero-ohm `}<strong parentName="p">{`LINK`}</strong>{`!`}<br parentName="p"></br>{`
`}{`Resistances less than 10 ohms have ‘R’ to indicate the position of the decimal point.`}<br parentName="p"></br>{`
`}{`Here are some examples:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Three Digit Examples`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Four Digit Examples`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`330 is 33 ohms `}<em parentName="td">{`(not 330 ohms)`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1000 is 100 ohms `}<em parentName="td">{`(not 1000 ohms)`}</em></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`221 is 220 ohms`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4992 is 49 900 ohms, or 49k9`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`683 is 68 000 ohms, or 68k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1623 is 162 000 ohms, or 162k`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`105 is 1 000 000 ohms, or 1M`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0R56 or R56 is 0.56 ohms`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`8R2 is 8.2 ohms`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <p>{`A new coding system has appeared on `}<strong parentName="p">{`1% types`}</strong>{`. This is known as the EIA-96 marking method. It consists of a three-character code. The first two digits signify the 3 significant digits of the resistor value, using the lookup table below. The third character - a letter - signifies the multiplier.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`code`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`value`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`code`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`value`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`code`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`value`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`code`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`value`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`code`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`value`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`code`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`value`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`01`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`100`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`17`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`147`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`33`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`215`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`49`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`316`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`65`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`464`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`81`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`681`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`02`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`102`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`18`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`150`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`34`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`221`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`50`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`324`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`66`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`475`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`82`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`698`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`03`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`105`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`19`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`154`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`35`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`226`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`51`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`332`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`67`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`487`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`83`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`715`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`04`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`107`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`20`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`158`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`36`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`232`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`52`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`340`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`68`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`499`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`84`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`732`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`05`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`110`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`21`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`162`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`37`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`237`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`53`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`348`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`69`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`511`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`85`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`750`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`06`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`113`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`22`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`165`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`38`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`243`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`54`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`357`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`70`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`523`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`86`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`768`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`07`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`115`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`23`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`169`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`39`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`249`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`55`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`365`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`71`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`536`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`87`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`787`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`08`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`118`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`24`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`174`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`40`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`255`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`56`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`374`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`72`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`549`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`88`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`806`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`09`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`121`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`25`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`178`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`41`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`261`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`57`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`383`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`73`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`562`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`89`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`825`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`10`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`124`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`26`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`182`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`42`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`267`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`58`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`392`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`74`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`576`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`90`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`845`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`11`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`127`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`27`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`187`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`43`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`274`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`59`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`402`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`75`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`590`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`91`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`866`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`12`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`130`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`28`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`191`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`44`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`280`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`60`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`412`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`76`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`604`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`92`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`887`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`13`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`133`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`29`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`196`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`45`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`287`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`61`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`422`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`77`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`619`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`93`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`909`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`14`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`137`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`30`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`200`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`46`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`294`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`62`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`432`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`78`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`634`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`94`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`931`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`15`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`140`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`31`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`205`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`47`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`301`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`63`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`442`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`79`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`649`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`95`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`953`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`16`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`143`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`32`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`210`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`48`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`309`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`64`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`453`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`80`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`665`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`96`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`976`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`The `}<strong parentName="p">{`multiplier`}</strong>{` letters are as follows:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`letter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`mult`}</th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}>{`letter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`mult`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`100000`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`B`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`E`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10000`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`D`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1000`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`X or S`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.1`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`C`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`100`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Y or R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.01`}</td>
        </tr>
      </tbody>
    </table>
    <p><strong parentName="p">{`22A`}</strong>{` is a 165 ohm resistor, `}<strong parentName="p">{`68C`}</strong>{` is a 49900 ohm (49k9) and `}<strong parentName="p">{`43E`}</strong>{` a 2740000 (2M74). This marking scheme applies to 1% resistors only.`}</p>
    <hr></hr>
    <p>{`A similar arrangement can be used for `}<strong parentName="p">{`2% and 5%`}</strong>{` tolerance types. The multiplier letters are identical to 1% ones, but occur `}<strong parentName="p">{`before`}</strong>{` the number code and the following `}<strong parentName="p">{`code`}</strong>{` is used:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`2%`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`5%`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`code`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`value`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`code`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`value`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`code`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`value`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`code`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`value`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`01`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`100`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`13`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`330`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`25`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`100`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`37`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`330`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`02`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`110`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`14`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`360`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`26`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`110`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`38`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`360`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`03`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`120`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`15`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`390`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`27`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`120`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`39`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`390`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`04`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`130`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`16`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`430`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`28`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`130`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`40`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`430`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`05`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`150`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`17`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`470`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`29`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`150`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`41`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`470`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`06`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`160`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`18`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`510`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`30`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`160`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`42`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`510`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`07`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`180`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`19`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`560`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`31`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`180`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`43`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`560`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`08`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`200`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`20`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`620`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`32`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`200`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`44`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`620`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`09`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`220`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`21`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`680`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`33`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`220`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`45`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`680`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`10`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`240`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`22`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`750`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`34`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`240`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`46`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`750`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`11`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`270`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`23`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`820`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`35`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`270`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`47`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`820`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`12`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`300`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`24`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`910`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`36`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`300`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`48`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`910`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`With this arrangement, `}<strong parentName="p">{`C31`}</strong>{` is 5%, 18000 ohm (18k), and `}<strong parentName="p">{`D18`}</strong>{` is 510000 ohms (510k) 2% tolerance.`}<br parentName="p"></br>{`
`}{`Always check with an ohm-meter (a multimeter) to make sure.`}</p>
    <p>{`Chip resistors come in the following styles and ratings:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Style:`}</strong>{` 0402, 0603, 0805, 1206, 1210, 2010, 2512, 3616, 4022`}</li>
      <li parentName="ul"><strong parentName="li">{`Power Rating:`}</strong>{` 0402(1/16W), 0603(1/10W), 0805(1/8W), 1206(1/4W), 1210(1/3W), 2010(3/4W), 2512(1W), 3616(2W), 4022(3W)`}</li>
      <li parentName="ul"><strong parentName="li">{`Tolerance:`}</strong>{` 0.1%, 0.5%, 1%, 5%`}</li>
      <li parentName="ul"><strong parentName="li">{`Temperature Coefficient:`}</strong>{` 25ppm 50ppm 100ppm`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      