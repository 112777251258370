import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import CardList from '@teComponents/ProductCard/CardList';
export const _frontmatter = {
  "title": "Test Equipment (this is dropped into articles)",
  "slug": "/articles/_test-equipment",
  "author": "Colin Mitchell",
  "private": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Talking Electronics has a number of pieces of TEST EQUIPMENT to help in the design and testing of projects.`}<br parentName="p"></br>{`
`}{`Of course you can use a multimeter for most of the testing but some of the “tricky” faults need a special piece of equipment.`}<br parentName="p"></br>{`
`}{`You may only need a LOGIC PROBE once a month, but the project you are designing will come to a stand-still if you can’t locate a problem.`}<br parentName="p"></br>{`
`}{`We designed all these projects because we needed them ourselves.`}<br parentName="p"></br>{`
`}{`Add one of them to each order you place with Talking Electronics and eventually you will have the whole range.`}</p>

    <CardList type='list' products={[{
      key: '/articles/27mhz-field-strength-meter'
    }, {
      key: '/articles/led-tester'
    }, {
      key: '/articles/continuity-tester'
    }, {
      key: '/articles/logic-probe-pulser-slimline'
    }, {
      key: '/articles/super-probe-mkii'
    }, {
      key: '/articles/combo-2'
    }, {
      key: 'Simple Transistor and LED Tester - 3'
    }, {
      key: '/articles/mains-tracer-with-probe'
    }, {
      key: '/articles/cable-tracer-100mhz'
    }, {
      key: 'OP-AMP TRAINER and TESTER'
    }, {
      key: '/articles/pic-fx'
    }, {
      key: '/articles/cdu-in-line-version'
    }]} variant='interactive' mdxType="CardList" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      