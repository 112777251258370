import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "slug": "/articles/chip-data-ebook-1d/_surface-mount-markings-3-digit",
  "private": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Click to see the complete range of SM resistor markings for 3-digit code:`}</p>
    <select name='menu1'>
  <option>0R1 = 0.1ohm</option>
  <option>R22 = 0.22ohm</option>
  <option>R33 = 0.33ohm</option>
  <option>R47 = 0.47ohm</option>
  <option>R68 = 0.68ohm</option>
  <option>R82 = 0.82ohm</option>
  <option>1R0 = 1R</option>
  <option>1R2 = 1R2</option>
  <option>2R2 = 2R2</option>
  <option>3R3 = 3R3</option>
  <option>4R7 = 4R7</option>
  <option>5R6 = 5R6</option>
  <option>6R8 = 6R8</option>
  <option>8R2 = 8R2</option>
  <option>100 = 10R</option>
  <option>120 = 12R</option>
  <option>150 = 15R</option>
  <option>180 = 18R</option>
  <option>220 = 22R</option>
  <option>270 = 27R</option>
  <option>330 = 33R</option>
  <option>390 = 39R</option>
  <option>470 = 47R</option>
  <option>560 = 56R</option>
  <option>680 = 68R</option>
  <option>820 = 82R</option>
  <option>101 = 100R</option>
  <option>121 = 120R</option>
  <option>151 = 150R</option>
  <option>181 = 180R</option>
  <option>221 = 220R</option>
  <option>271 = 270R</option>
  <option>331 = 330R</option>
  <option>391 = 390R</option>
  <option>471 = 470R</option>
  <option>561 = 560R</option>
  <option>681 = 680R</option>
  <option>821 = 820R</option>
  <option>102 = 1k0</option>
  <option>122 = 1k2</option>
  <option>152 = 1k5</option>
  <option>182 = 1k8</option>
  <option>222 = 2k2</option>
  <option>272 = 2k7</option>
  <option>332 = 3k3</option>
  <option>392 = 3k9</option>
  <option>472 = 4k7</option>
  <option>562 = 5k6</option>
  <option>682 = 6k8</option>
  <option>822 = 8k2</option>
  <option>103 = 10k</option>
  <option>123 = 12k</option>
  <option>153 = 15k</option>
  <option>183 = 18k</option>
  <option>223 = 22k</option>
  <option>273 = 27k</option>
  <option>333 = 33k</option>
  <option>393 = 39k</option>
  <option>473 = 47k</option>
  <option>563 = 56k</option>
  <option>683 = 68k</option>
  <option>823 = 82k</option>
  <option>104 = 100k</option>
  <option>124 = 120k</option>
  <option>154 = 150k</option>
  <option>184 = 180k</option>
  <option>224 = 220k</option>
  <option>274 = 270k</option>
  <option>334 = 330k</option>
  <option>394 = 390k</option>
  <option>474 = 470k</option>
  <option>564 = 560k</option>
  <option>684 = 680k</option>
  <option>824 = 820k</option>
  <option>105 = 1M0</option>
  <option>125 = 1M2</option>
  <option>155 = 1M5</option>
  <option>185 = 1M8</option>
  <option>225 = 2M2</option>
  <option>275 = 2M7</option>
  <option>335 = 3M3</option>
  <option>395 = 3M9</option>
  <option>475 = 4M7</option>
  <option>565 = 5M6</option>
  <option>685 = 6M8</option>
  <option>825 = 8M2</option>
  <option>106 = 10M0</option>
    </select>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      