import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Basic Electronics 1A",
  "slug": "/articles/basic-electronics-1a/_article-navigation",
  "category": "Articles",
  "originalUrl": "none",
  "author": "Colin Mitchell",
  "private": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "contents-of-article"
    }}>{`Contents of Article`}</h1>
    <ul>
      <li parentName="ul">{`Page 1: `}<a parentName="li" {...{
          "href": "/articles/basic-electronics-1a"
        }}>{`Basic Electronics`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/how-a-capacitor-works"
        }}>{`The capacitor`}</a>{` - how it works`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/how-a-diode-works"
        }}>{`The Diode`}</a>{` - how the diode works`}</li>
      <li parentName="ul">{`Every `}<a parentName="li" {...{
          "href": "/articles/circuit-symbols"
        }}>{`Circuit Symbols`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/basic-electronics-1a/soldering"
        }}>{`Soldering Videos`}</a></li>
      <li parentName="ul">{`Page 2: `}<a parentName="li" {...{
          "href": "/articles/basic-electronics-1a/page-2"
        }}>{`The Transistor`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/articles/circuit-symbols/pnp-or-npn"
            }}>{`PNP or NPN`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/articles/circuit-symbols/pnp-or-npn"
            }}>{`Transistor TEST`}</a></li>
          <li parentName="ul">{`Page 2a: `}<a parentName="li" {...{
              "href": "/articles/basic-electronics-1a/page-2a"
            }}>{`The 555 IC`}</a>
            <ul parentName="li">
              <li parentName="ul">{`The `}<a parentName="li" {...{
                  "href": "/articles/555"
                }}>{`555 - 1`}</a></li>
              <li parentName="ul">{`The `}<a parentName="li" {...{
                  "href": "/articles/555/test"
                }}>{`555 TEST`}</a></li>
            </ul>
          </li>
        </ul>
      </li>
      <li parentName="ul">{`Page 3: `}<a parentName="li" {...{
          "href": "/articles/basic-electronics-1a/page-3"
        }}>{`The Power Supply`}</a>
        <ul parentName="li">
          <li parentName="ul">{`3a: `}<a parentName="li" {...{
              "href": "/articles/circuit-symbols/the-voltage-regulator/constant-current"
            }}>{`Constant Current`}</a></li>
          <li parentName="ul">{`3b: `}<a parentName="li" {...{
              "href": "/articles/circuit-symbols/the-voltage-regulator"
            }}>{`Voltage Regulator`}</a></li>
          <li parentName="ul">{`3c: `}<a parentName="li" {...{
              "href": "/articles/basic-electronics-1a/page-3c"
            }}>{`Capacitor-fed Power Supply`}</a></li>
        </ul>
      </li>
      <li parentName="ul">{`Page 4: `}<a parentName="li" {...{
          "href": "/articles/basic-electronics-1a/page-4"
        }}>{`Digital Electronics`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/articles/circuit-symbols/page-11"
            }}>{`Gates`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/articles/circuit-symbols/page-11a"
            }}>{`Touch Switch`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/articles/circuit-symbols/page-12"
            }}>{`Gating`}</a></li>
          <li parentName="ul">{`4b: The `}<a parentName="li" {...{
              "href": "/articles/basic-electronics-1a/page-4b"
            }}>{`DELAY CIRCUIT`}</a></li>
        </ul>
      </li>
      <li parentName="ul">{`Page 5: `}<a parentName="li" {...{
          "href": "/articles/basic-electronics-1a/page-5"
        }}>{`Oscillators`}</a></li>
      <li parentName="ul">{`Page 6: Basic Electronics `}<a parentName="li" {...{
          "href": "/articles/basic-electronics-1a/page-6"
        }}>{`50 Questions Test`}</a></li>
      <li parentName="ul">{`Page 7: using `}<a parentName="li" {...{
          "href": "/articles/basic-electronics-1a/page-7"
        }}>{`The Multimeter`}</a></li>
      <li parentName="ul">{`Page 8: Constructing a Project`}</li>
      <li parentName="ul">{`Page 9: `}<a parentName="li" {...{
          "href": "/articles/basic-electronics-1a/page-9-inductance"
        }}>{`Inductance`}</a></li>
    </ul>
    <hr></hr>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      