import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "slug": "/articles/chip-data-ebook-1d/_surface-mount-markings-4-digit",
  "private": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Click to see the complete range of SM resistor markings for 4-digit code:`}</p>
    <select name='menu'>
  <option>0000 =00R</option>
  <option>00R1 = 0.1ohm</option>
  <option>0R22 = 0.22ohm</option>
  <option>0R47 = 0.47ohm</option>
  <option>0R68 = 0.68ohm</option>
  <option>0R82 = 0.68ohm</option>
  <option>1R00 = 1ohm</option>
  <option>1R20 = 1R2</option>
  <option>2R20 = 2R2</option>
  <option>3R30 = 3R3</option>
  <option>6R80 = 6R8</option>
  <option>8R20 = 8R2</option>
  <option>10R0 = 10R</option>
  <option>11R0 = 11R</option>
  <option>12R0 = 12R</option>
  <option>13R0 = 13R</option>
  <option>15R0 = 15R</option>
  <option>16R0 = 16R</option>
  <option>18R0 = 18R</option>
  <option>20R0 = 20R</option>
  <option>22R0 = 22R</option>
  <option>24R0 = 24R</option>
  <option>27R0 = 27R</option>
  <option>30R0 = 30R</option>
  <option>33R0 = 33R</option>
  <option>36R0 = 36R</option>
  <option>39R0 = 39R</option>
  <option>43R0 = 43R</option>
  <option>47R0 = 47R</option>
  <option>51R0 = 51R</option>
  <option>56R0 = 56R</option>
  <option>62R0 = 62R</option>
  <option>68R0 = 68R</option>
  <option>75R0 = 75R</option>
  <option>82R0 = 82R</option>
  <option>91R0 = 91R</option>
  <option>1000 = 100R</option>
  <option>1100 = 110R</option>
  <option>1200 = 120R</option>
  <option>1300 = 130R</option>
  <option>1500 = 150R</option>
  <option>1600 = 160R</option>
  <option>1800 = 180R</option>
  <option>2000 = 200R</option>
  <option>2200 = 220R</option>
  <option>2400 = 240R</option>
  <option>2700 = 270R</option>
  <option>3000 = 300R</option>
  <option>3300 = 330R</option>
  <option>3600 = 360R</option>
  <option>3900 = 390R</option>
  <option>4300 = 430R</option>
  <option>4700 = 470R</option>
  <option>5100 = 510R</option>
  <option>5600 = 560R</option>
  <option>6200 = 620R</option>
  <option>6800 = 680R</option>
  <option>7500 = 750R</option>
  <option>8200 = 820R</option>
  <option>9100 = 910R</option>
  <option>1001 = 1k0</option>
  <option>1101 = 1k1</option>
  <option>1201 = 1k2</option>
  <option>1301 = 1k3</option>
  <option>1501 = 1k5</option>
  <option>1601 = 1k6</option>
  <option>1801 = 1k8</option>
  <option>2001 = 2k0</option>
  <option>2201 = 2k2</option>
  <option>2401 = 2k4</option>
  <option>2701 = 2k7</option>
  <option>3001 = 3k0</option>
  <option>3301 = 3k3</option>
  <option>3601 = 3k6</option>
  <option>3901 = 3k9</option>
  <option>4301 = 4k3</option>
  <option>4701 = 4k7</option>
  <option>5101 = 5k1</option>
  <option>5601 = 5k6</option>
  <option>6201 = 6k2</option>
  <option>6801 = 6k8</option>
  <option>7501 = 7k5</option>
  <option>8201 = 8k2</option>
  <option>9101 = 9k1</option>
  <option>1002 = 10k</option>
  <option>1102 = 11k</option>
  <option>1202 = 12k</option>
  <option>1302 = 13k</option>
  <option>1502 = 15k</option>
  <option>1602 = 16k</option>
  <option>1802 = 18k</option>
  <option>2002 = 20k</option>
  <option>2202 = 22k</option>
  <option>2402 = 24k</option>
  <option>2702 = 27k</option>
  <option>3002 = 30k</option>
  <option>3302 = 33k</option>
  <option>3602 = 36k</option>
  <option>3902 = 39k</option>
  <option>4302 = 43k</option>
  <option>4702 = 47k</option>
  <option>5102 = 51k</option>
  <option>5602 = 56k</option>
  <option>6202 = 62k</option>
  <option>6802 = 68k</option>
  <option>7502 = 75k</option>
  <option>8202 = 82k</option>
  <option>9102 = 91k</option>
  <option>1003 = 100k</option>
  <option>1103 = 110k</option>
  <option>1203 = 120k</option>
  <option>1303 = 130k</option>
  <option>1503 = 150k</option>
  <option>1603 = 160k</option>
  <option>1803 = 180k</option>
  <option>2003 = 200k</option>
  <option>2203 = 220k</option>
  <option>2403 = 240k</option>
  <option>2703 = 270k</option>
  <option>3003 = 300k</option>
  <option>3303 = 330k</option>
  <option>3603 = 360k</option>
  <option>3903 = 390k</option>
  <option>4303 = 430k</option>
  <option>4703 = 470k</option>
  <option>5103 = 510k</option>
  <option>5603 = 560k</option>
  <option>6303 = 620k</option>
  <option>6803 = 680k</option>
  <option>7503 = 750k</option>
  <option>8203 = 820k</option>
  <option>9103 = 910k</option>
  <option>1004 = 1M</option>
  <option>1104 = 1M1</option>
  <option>1204 = 1M2</option>
  <option>1304 = 1M3</option>
  <option>1504 = 1M5</option>
  <option>1604 = 1M6</option>
  <option>1804 = 1M8</option>
  <option>2004 = 2M0</option>
  <option>2204 = 2M2</option>
  <option>2404 = 2M4</option>
  <option>2704 = 2M7</option>
  <option>3004 = 3M0</option>
  <option>3304 = 3M3</option>
  <option>3604 = 3M6</option>
  <option>3904 = 3M9</option>
  <option>4304 = 4M3</option>
  <option>4704 = 4M7</option>
  <option>5104 = 5M1</option>
  <option>5604 = 5M6</option>
  <option>6204 = 6M2</option>
  <option>6804 = 6M8</option>
  <option>7504 = 7M5</option>
  <option>8204 = 8M2</option>
  <option>9104 = 9M1</option>
  <option>1005 = 10M</option>
    </select>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      