import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Elektor",
  "slug": "/articles/elektor",
  "category": "Articles",
  "tags": ["#elektor"],
  "date": "2009-08-12T00:00:00.000Z",
  "thumbnail": null,
  "thumbnailText": "TODO",
  "originalUrl": "http://www.talkingelectronics.com/FrameworkPages/cont12-Elektor.html",
  "author": "Colin Mitchell"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <pre><code parentName="pre" {...{}}>{`<!-- TODO FIXME COLIN: do you want this to be a table of contents on all elektor pages, instead?
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "/articles/how-to-order"
      }}>{`Ordering Items`}</a></p>
    <h2 {...{
      "id": "elektor-epe--silicon-chip-projects"
    }}>{`ELEKTOR, EPE & SILICON CHIP PROJECTS`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/article/learn-microcontrollers"
        }}>{`Chapter 1`}</a>{` Introduction`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "http://www.pictutorials.com/PIC_books.htm"
            }}>{`12 FREE PIC Books`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "http://o.mneina.googlepages.com/PIC_books.htm"
            }}>{`12 FREE PIC Books (alternate URL)`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/talk-about-micros"
        }}>{`Chapter 2`}</a>{` Talking About Micro’s`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/talk-about-micros/page-2"
        }}>{`Chapter 2a`}</a>{` State Machine`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/start-here-with-12f629"
        }}>{`Chapter 3`}</a>{` Start HERE with PIC12F629`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/in-circuit-programming"
        }}>{`Chapter 4`}</a>{` In Circuit Programming`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/changing-micros"
        }}>{`Chapter 5`}</a>{` Changing Micros`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/delays"
        }}>{`Chapter 6`}</a>{` Delay Codes`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/articles/start-here-with-12f629/files/blank12F629corrected.asm"
            }}>{`blank template`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/articles/start-here-with-12f629/library-of-routines-for-pic12f629"
            }}>{`Decimal to HEX`}</a></li>
          <li parentName="ul">{`Coding Problems, or Program Problems, or Writing Problems`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/start-here-with-pic16f628"
        }}>{`Chapter 7`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/articles/start-here-with-pic16f628"
            }}>{`Start Here with PIC16F628`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/articles/start-here-with-pic16f628/instruction-set"
            }}>{`PIC16F628 Instructions`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/articles/start-here-with-pic16f628/files/PIC16F628.pdf"
            }}>{`PIC16F628 PDF Data Sheet`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/articles/start-here-with-pic16f628"
            }}>{`PICkit-2 programmer`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/article/learn-microcontrollers/other-peoples-programs"
        }}>{`Chapter 8`}</a>{` Other people’s programs (to help you write code)`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/articles/elmer-160"
            }}>{`Elmer 160 (PIC lessons on the web - by J McDonough)`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/articles/gooligum-pic-tutorials"
            }}>{`Gooligum PIC Tutorials`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "../projects/Elektor/Chapter%209/Chapter%209.html"
        }}>{`Chapter 9`}</a>{` Converting PIC’508A code to PIC12F629 code`}</li>
    </ul>
    <h2 {...{
      "id": "projects"
    }}>{`PROJECTS`}</h2>
    <p>{`Some of these projects are not yet finished:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/2-digit-up-down-counter"
        }}>{`2 Digit Up/Down Counter`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/12-fxxx-proto-board"
        }}>{`12Fxxx ProtoBoard`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "../projects/7x5GameConsole/7x5GameConsole-1.html"
        }}>{`7x5 Games Console`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/alarm-space-gun"
        }}>{`Alarm - Space Gun`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/audio-cro"
        }}>{`Audio CRO`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/2-digit-up-down-counter/page-5"
        }}>{`Bike & Car Gears`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/call-reminder"
        }}>{`Call Reminder`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/code-puzzle"
        }}>{`Code Puzzle`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/dial-alarm-2"
        }}>{`Dial Alarm-2`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/dual-led-5x7-display"
        }}>{`Dual LED 5x7 Display`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/eeprom-write"
        }}>{`EEPROM Write`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/fish-shop-timer"
        }}>{`Fish Shop Timer`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/inductance-meter"
        }}>{`Inductance Meter`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/led-fx"
        }}>{`LED FX`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/mini-frequency-counter"
        }}>{`Mini Frequency Counter`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/sky-writer"
        }}>{`Sky Writer`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/spin-the-dice"
        }}>{`Spin The Dice`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/stroop-game"
        }}>{`Stroop Game`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/talk-to-me"
        }}>{`Talk To Me`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/whistle"
        }}>{`Whistle Key Finder`}</a></li>
    </ul>
    <p>{`Things you will need:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/start-here-with-12f629/files/blank12F629corrected.asm"
        }}>{`blank template`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/start-here-with-12f629/library-of-routines-for-pic12f629"
        }}>{`Decimal to HEX`}</a></li>
      <li parentName="ul">{`Disassembly `}<a parentName="li" {...{
          "href": "/article/learn-microcontrollers/files/picdisasm106.zip"
        }}>{`zip`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/article/learn-microcontrollers/files/cml_v0p5.zip"
        }}>{`Code Microsoft Library`}</a>{` (unzip it into a folder CML.)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/article/learn-microcontrollers/files/20%20x%20.inc%20files.ace"
        }}>{`.inc files`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "../Download/icprog106B.zip"
        }}>{`IC Prog 106B`}</a>{` (`}<a parentName="li" {...{
          "href": "../Download/icproghh_eng.zip"
        }}>{`Help file`}</a>{`)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/start-here-with-12f629/instruction-set-for-pic12f629"
        }}>{`Instructions`}</a>{` for PIC12F629`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/start-here-with-12f629/library-of-routines-for-pic12f629"
        }}>{`Library of Routines A-E`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/start-here-with-12f629/library-of-routines-for-pic12f629/page-2"
        }}>{`Library of Routines E-P`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/start-here-with-12f629/library-of-routines-for-pic12f629/page-3"
        }}>{`Library of Routines P-Z`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/in-circuit-programming/files/MPASM/MPASMWIN.rar"
        }}>{`MPASM`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/multi-chip-programmer"
        }}>{`Multi Chip Programmer`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/chip-data-ebook-1d/pic-chip-list"
        }}>{`PIC Chip List`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/multi-chip-programmer"
        }}>{`PIC Programmer`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/prototyping-boards"
        }}>{`Prototyping Boards`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/article/pic10f222"
        }}>{`PIC10F222`}</a>{` - 6 pin SM micro`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/article/pic10f222-mini-pic-dice"
        }}>{`PIC10F222 - PIC Mini Dice`}</a>{` - DICE`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/article/pic10f222-ignition-starter"
        }}>{`PIC10F222 - Ignition Starter`}</a>{` - Ignition/starter`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/start-here-with-12f629/files/p12F629.inc"
        }}>{`p12F629.inc`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/start-here-with-12f629/files/PIC12F629_F675DataSheet.pdf"
        }}>{`PIC12F629`}</a>{` Data Sheet (.pdf 4,926KB)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/start-here-with-pic16f628"
        }}>{`PICkit-2 programmer`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.modtronix.com/product_info.php?products_id=257"
        }}>{`PICkit-2 from Modtronix`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/in-circuit-programming"
        }}>{`6 pin - 5 pin adapter`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/surface-mount-pc-board-for-pic-12f629"
        }}>{`Surface Mount PC Board`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/chip-data-ebook-1d/surface-mount-diodes"
        }}>{`S/mount Diodes/Zeners`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/chip-data-ebook-1d/surface-mount-leds"
        }}>{`Surface Mount LEDs`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/chip-data-ebook-1d/surface-mount-resistors"
        }}>{`Surface Mount Resistors`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/chip-data-ebook-1d/surface-mount-transistors"
        }}>{`Surface Mount Transistors`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/chip-data-ebook-1d/surface-mount-outlines"
        }}>{`Surface Mount Outlines`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      