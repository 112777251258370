import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "slug": "/articles/chip-data-ebook-1d/_surface-mount-data-table",
  "private": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ul>
      <li parentName="ul">{`*`}{` = SOT 23`}</li>
      <li parentName="ul">{`#`}{` = SOT 323`}</li>
      <li parentName="ul">{`+`}{` = SOT 89`}</li>
      <li parentName="ul">{`EM3: L=1.6mm W=1.6mm`}</li>
      <li parentName="ul">{`UMT: L=2mm W=2.1mm`}</li>
      <li parentName="ul">{`SC70: L=2mm W=2.1mm`}</li>
      <li parentName="ul">{`SMT: L=2.9mm W=2.9mm`}</li>
      <li parentName="ul">{`SOT-23: L=2.9mm W=2.9mm`}</li>
      <li parentName="ul">{`SOT-89: L=1.6mm W=1.6mm`}</li>
      <li parentName="ul">{`SOT-323: L=2mm W=2.1mm`}</li>
    </ul>
    <p><strong parentName="p">{`‘R’ indicates reverse leads`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Marking Code:`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type Number:`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SC3722K`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC846A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MMBT3904 (Fair)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1B`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC846B`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1BR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC846AR`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1D`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC846`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1D`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MMBTA42 (Fair)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1E`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC847A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1ER`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC847AR`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC847B`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1FR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC847BR`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1G`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC847C`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1GR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC847CR`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1H`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC847`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1J`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC848A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1JR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC848AR`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1K`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC848B`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1KR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC848BR`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1L`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC848C`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1LR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC848CR`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1M`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC848`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1P`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MMBT222A (Fair)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1S`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MMBT2369A (Fair)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1V`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BF820`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BF821`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1X`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BF822`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BF823`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MMBT3906 (Fair)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2B`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC849B`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2BR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC849BR`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2C`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC849C`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2CR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC849CR`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2D`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MMBTA92 (FAIR)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC850B`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MMBT2907A (Fair)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2FR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC850BR`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2G`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC850C`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2GR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC850CR`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`02`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BST82`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`0A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTC125TK (SMT)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`03`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTC143TKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`04`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTC114TKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`05`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTC124TKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`06`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTC144TKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`09`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTC115TKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`12`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTA123EKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`13`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTA143EKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`14`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTA114EKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`15`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTA124EKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`16`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTA114EKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`19`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTA115EKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1B`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`IRLML2803`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1C`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`IRLML6302`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1C`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SC4082 (UMT)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1D`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`IRLML5103`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1D`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SC4083 (UMT)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1E`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SC4084 (UMT)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1L`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SC4103 (UMT)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1M`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SC4128 (UMT)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1T`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SC4773 (UMT)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`22`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTC123EKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`23`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTC143EKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`24`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTC114EKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`25`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTC124EKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`26`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTC144EKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`29`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTC115EKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2X`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MMBT4401 (Fair)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`303`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FDV303N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`310`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FDV301N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`304`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FDV304P`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`302`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FDV302P`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`331`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NDS331N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`332`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NDS332P`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`33`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTA143XKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`335`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NDS335N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`336`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NDS336P`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`337`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FDN337N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`338`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FDN338P`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`351`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NDS351N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`352`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NDS352P`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`355`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NDS355N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`356`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NDS356P`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`357`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FDN357N`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`358`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FDN358P`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`35`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTA124XKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC856A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3AR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC856AR`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3B`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC856B`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3BR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC856BR`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3D`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC856`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3E`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC857A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3E`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FMMTA42 (ZETex)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3ER`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC857AR`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC857B`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3FR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC857BR`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3G`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC857C`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3GR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC857CR`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3J`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC858A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3JR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC858AR`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3K`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC858B`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3KR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC858BR`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3L`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC858C`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3LR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC858CR`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MMBT5771 (Fair)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3S`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MMBT5551 (Fair)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`43`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTC143XKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`45`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTC124XKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC859A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4AR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC859AR`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4B`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC859B`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4BR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC859BR`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4C`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC859C`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4CR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC859CR`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4E`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC860A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4ER`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC860AR`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC860B`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4FR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC860BR`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4G`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC860C`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4GR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC860CR`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`41A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FMMT491A (ZET)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`44`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BAS40-04 (SIE)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`458`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FMMT458 (ZET)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`489`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FMMT489 (ZET)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`491`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FMMT491 (ZET)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`493`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FMMT493 (ZET)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`52`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTA123YKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`53`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTA143YKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`54`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTA114YKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`5A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC807-16`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`5AR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC807-16R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`5B`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC807-25`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`5BR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC807-25R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`5C`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC807-40`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`5CR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC807-40R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`5D`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MMBD914 (Fair)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`5E`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC808-16`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`5ER`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC808-16R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`5F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC808-25`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`5FR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC808-25R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`5G`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC808-40`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`5GR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC808-40R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`5H`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MMBD4148 (Fair)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`558`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FMMT558 (ZET)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`589`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FMMT589 (ZET)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`591`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FMMT591 (ZET)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`593`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FMMT593 (ZET)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`605`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NDS0605`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`610`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NDS0610`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`62`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTC123YKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`63`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTC143YKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`64`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTC114YKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`69`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTC115TE (EM3)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`6A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC817-16`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`6AR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC817-16R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`6B`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC817-25`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`6BR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC817-25R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`6C`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC817-40`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`6CR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC817-40R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`6E`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC818-16`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`6ER`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC818-16R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`6F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC818-25`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`6FR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC818-25R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`6G`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC818-40`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`6GR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC818-40R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`605`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NDS0605`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`610`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NDS0610`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`617`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FMMT617 (ZET)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`618`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FMMT618 (ZET)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`619`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FMMT619 (ZET)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`702`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2N7002`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`702`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2N7002LT1`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`717`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FMMT717 (ZET)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`718`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FMMT718 (ZET)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`720`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FMMT720 (ZET)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`72`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2N7002`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`74`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BAS70-04 (SIE)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`74`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTA114WKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`76`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTA144WKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`84`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTC114WKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`86`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTC144WKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`9A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTA125TK`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`91`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTA113TKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`93`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTA143TKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`94`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTA114TKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`95`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTA124TKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`96`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTA144TKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`99`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTA115TKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`111`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTA113ZUA (UMT)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`113`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTA143ZUA (UMT)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`121`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTC113ZUA (UMT)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`123`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTC143ZUA (UMT)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`132`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTA123JUA (UMT)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`142`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTC123JUA (UMT)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`156`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTA144VUA (UMT)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`166`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTC144VUA (UMT)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`179`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DAT115UU (UMT)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`183`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTC115UU (UMT)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SC4618 (EM3)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SC4098 (UMT)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SC2413K (SMT)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`A1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SS355 (ROHM)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`A0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HSMS-2800 (HP)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`A1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BAW56`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`A11`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MMBD1501A (Fair)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`A13`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MMBD1503A (Fair)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`A14`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MMBD1504A (Fair)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`A15`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MMBD1505A (Fair)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`A2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BAT18`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`A2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HSMS-2802 (HP)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`A3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BAT17`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`A4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BAV70`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`A5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BRY61`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`A6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BRY62`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`A7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BAS16`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`A8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BAS28`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`A51`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BAV99`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`A61`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BASl9`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`A81`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BAS20`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`A82`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BAS21`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`A91`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BAS17`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SC2413K`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AB`}{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCW60B`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AB+`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCX51-6`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AC`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SC3837K`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AC`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SC4725 (EM3)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AC`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SC3837K (SMT)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AC`}{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCW60C`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AC+`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCX51-10`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SC3838K`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SC4726 (EM3)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SC3838K (SMT)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AD`}{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCW60D`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AD+`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCX51-16`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SC3839K`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCX52`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCX52-6`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SD1781K`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AG`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCX70G`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AH`}{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCX70H`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AH+`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCX53`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AH`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SB1197K`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AJ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SD1782K`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AJ`}{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCX70J`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AJ+`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCX53-6`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AJ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCX70JLT1 (Mot)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AK`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SB1198K`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AK`}{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCX70K`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AK+`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCX53-10`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCX53-16`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SC3802K`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AM`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCX52-16`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AM`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SC4018K`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AN`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SC4061K`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SC4074K`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AQ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SB0151K`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AR1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BSR40`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AR2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BSR41`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AR3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BSR42`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AR4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BSR43`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AS1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BST50`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AS2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BST51`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AS3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BST52`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AT1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BST39`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AT2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BST40`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AT`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SC4326K`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`B`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SC2412K`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`B`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SC4617 (EM3)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`B`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SC4081 (UMT)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`B0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HSMS-2810 (HP)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`B0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC847`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`B2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BSV52`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`B2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HSMS-2812 (HP)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`B4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BSV52R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`B4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HSMS-2814 (HP)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`B5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BSR12`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`B5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HSMS-2815 (HP)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`B8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BSR12R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DAN217K (ROHM)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BA`}{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCW61A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BA+`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCX54`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BB`}{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCW61B`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BB+`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCX54-6`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BB`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SD2114K`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BC`}{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCW61C`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BC+`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCX54-10`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BD`}{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCW61D`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BD+`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCX54-16`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SA1821 (EM3)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SA1808 (UMT)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SA1733K`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCX55`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SC4723 (UMT)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCX55-6`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SC4642K`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BG`}{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCX71G`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BG+`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCX55-10`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BH`}{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCX71H`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BH+`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCX56`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BH`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SC4699K`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BH`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SC4700 (UMT)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BJ`}{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCX71J`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BJ+`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCX56-6`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BJ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SD2226K`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BJ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SD2351 (UMT)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BK`}{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCX71K`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BK+`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCX56-10`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCX56-16`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SC4771K or 2`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BM`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SC4713K`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BM`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCX55-16`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BM`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SC4774 (UMT)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BR1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BSR30`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BR2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BSR31`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BR3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BSR32`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BR4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BSR33`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BS1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BST60`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BS2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BST61`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BS3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BST62`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BT1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BST15`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`BT2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BST16`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`C`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SC2411K`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`C`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SC4097 (UMT)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`C1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCW29`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`C2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCW30`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`C2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HSMS-2822 (HP)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`C4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCW29R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`C5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCW30R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`C7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCF29`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`C77`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCF29R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`C8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCF30`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`C9`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCF30R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`C91`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCV62`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCX68`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SA1885 (EM3)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SA1886 (UMT)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CAC`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC868`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CB`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SC4997 (EM3)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CB`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SC4998 (UMT)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCX69`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CEC`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC869`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`D`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1SS376 (ROHM)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`D)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SA1037AKLS`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`D0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HSMP-3800 (HP)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`D1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCW31`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`D2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCW32`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`D20`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HSMP-3802 (HP)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`D3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCW33`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`D3E`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`RB411D (ROHM)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`D4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCW31R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`D4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HSMP-3804 (HP)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`D5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCW32R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`D6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCW33R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`D7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCF32`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`D8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCF33`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`D58`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FLLD258 (ZET)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`D63`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FLLD263 (ZET)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`D76`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BAR18 (ST)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`D77`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCF32R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`D81`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCF33R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`D91`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCV61`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`D94`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BAR42 (ST)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`D95`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BAR43 (ST)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`D96`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BAS70-04 (ST)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`DA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BF622`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`DA5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BAR43S (ST)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`DB`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BF623`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`DB1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BAR43A (ST)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`DB2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BAR43C (ST)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`DC`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BF620`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`DF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BF621`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`DH`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCW68GLT1 (Mot)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`E`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1SS380 (ROHM)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`E1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BFS17`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`E2`}{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BFS17A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`E2#`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BF547W`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`E4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BFS17R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`E4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HSMP-3814 (HP)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`E6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BFG17A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`E11`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTA113ZKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`E13`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTA143ZKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`E15`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BF747`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`E16`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BF547`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`E21`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTC113ZKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`E23`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTC143ZKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`E32`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTA123JKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`E42`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTC123JKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`E56`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTA144VKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`E66`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTC144VKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`E79`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTA115UK`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`E89`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTC115UK`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`E92`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTB123TK`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`E93`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTB142TK`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`E94`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTB114TK`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`E97`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTB163TK`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`EC`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCW65C (Fair)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCV28`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`EE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCV48`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`EF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCV29`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`EF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCW66GLT1(Mot)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`EG`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCV49`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SA1037AK`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SA1774 (EM3)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SA1576A (UMT)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`F0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HSMP-3820 (HP)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`F1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BFS18`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`F2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BFS19`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`F2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HSMP-3822 (HP)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`F02`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTD123TK`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`F03`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTB143TK`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`F3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BF840`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`F04`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTD114TK`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`F4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BFS18R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`F4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HSMP-3824 (HP)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`F5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BFS19R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`F07`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTD163TK`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`F8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BF824`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`F11`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTB113EK`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`F12`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTB123EK`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`F13`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTB143EK`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`F14`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTB114EK`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`F21`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTD113EK`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`F22`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTD123EK`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`F23`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTD143EK`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`F24`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTD114EK`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`F31`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BF841`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`F52`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTB123YK`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`F62`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTD123YK`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`FA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BFQ17`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`FB`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BFQ19`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`FD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCV26`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`FF`}{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCV27`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`FF+`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BFO18A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`FR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC857`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`G`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SA1455K`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`G0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HSMP-3890 (HP)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`G1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BFS20`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`G1K`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC848B (ROHM)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`G1L`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC848C (ROHM)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`G2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BF550`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`G2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HSMP-3892 (HP)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`G3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BF536`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`G3C`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTB122JK`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`G3F`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC857B (ROHM)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`G3K`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC858B (ROHM)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`G4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BFS20R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`G4C`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTD122JK`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`G5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BF550R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`G6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BF569`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`G7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BF579`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`G8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BF660`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`G08`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTD133HK`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`G9`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BF767`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`G11`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTB113ZK`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`G21`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTD113ZK`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`G81`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BF660R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`G98`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTB133HK`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`GF1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BC847B (ROHM)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`GT1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCX17 (ROHM)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`GU1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCX19 (ROHM)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`H`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SA1036K`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`H1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCW69`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`H`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SA1577 (UMT)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`H02`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTC323TK`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`H2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCW70`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`H3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCW89`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`H03`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTC343TK`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`H31`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCW89R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`H4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTC314TK`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`H04`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCW69R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`H5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCW70R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`H7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCF70`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`H07`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTC363TK`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`H27`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTC363EK`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`H71`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCF70R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`I`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SC3722K (SMT)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`J`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SC2059K`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`J`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SC4649 (EM3)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`J`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SC4099 (UMT)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`JP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BAS19 (FAIR)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`JS`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BAS21 (SIE)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`JV`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BAS116 (SIE)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`JY)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BAV119 (SIE)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`K1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCW71`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`K2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCW72`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`K3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCW81`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`K3B`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTA1D3RKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`K4B`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTC1D3RKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`K4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCW71R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`K5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCW72R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`K7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCV71`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`K8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCV72`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`K9`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCF81`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`K14`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTA114GKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`K15`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTA124GKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`K16`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTA144GKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`K19`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTA115GKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`K24`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTC114GKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`K25`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTC124GKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`K26`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTC144GKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`K29`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTC115GKA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`K31`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCW81R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`K71`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCV71R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`K81`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCV72R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`K91`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCF81R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`KM`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BST80`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`KN`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BST84`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`KO`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BST66`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`L`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SC2412KLN`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`L4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BAT54`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`L14`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTB114GK`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`L20`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BAS29`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`L21`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BAS31`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`L22`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BAS35`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`L24`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTD114GK`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`L30`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BAV23`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`L41`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BAT74`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`L42`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BAT54A (ZET)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`L43`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BAT54C`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`L44`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BAT54S`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`L51`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BAS56`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`LM`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BST120`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`LN`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BST122`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`M`}{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCW60A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`M+`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCX51`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`M`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SD1757K`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`M`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SD1757K (SMT)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`M1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BFR30`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`M2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BFR32`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`M3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BFT46`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`M4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BSR56`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`M5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BSR57`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`M6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BSR58`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`M31`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BSD20`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`M32`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BSD22`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`M62`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`PBMF4391`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`M63`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`PBMF4392`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`M64`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`PBMF4393`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`M65`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BF545A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`M66`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BF545B`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`M67`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BF545C`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`M74`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BSS83`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`M8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BSN20`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`M84`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BF556A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`M85`}{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BF556B`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`M86`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BF556C`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`M87`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BF99OA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`M89`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BF989`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`M90`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BF990`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`M91`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BF991`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`M92`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BF992`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`M94`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BF994`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`M96`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BF996`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`M97`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BFR101A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`M98`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BFR101B`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`MC`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ZVN3306F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`MF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ZVN3310F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ML`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ZVP3306F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`MR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ZVP3310F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`MY`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`VN10LF`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`MZ`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ZVN4106F`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`N0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TN0200T`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`N1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TN0201T`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`N1`}{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BFR53`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`N2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BFS520`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`N28`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BFR520`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`N29`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BFR540`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`N3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BFG520W`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`N30`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BFR505`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`N33`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BFG505`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`N36`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BFG520`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`N37`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BFG540`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`N38`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BFG590`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`N39`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BFG505/X`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`N4`}{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BFR53R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`N4#`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BFS540`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`N4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BFG520W/X`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`N6#`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BFS25A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`N70`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BFG10`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`N71`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BFG10/X`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`N72`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BFGll`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`N73`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BFGll/X`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`NC`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BF840`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ND`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BF841`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`OA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DTC125TK`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`P0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TP0101T`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`P1`}{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BFR92`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`P2#`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BFR92AW`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`P3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TP0202T`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`P4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BFR92R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`P5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BFR92AR`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`P8A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FLLD261 (ZET)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SA1514K`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SA1579 (UMT)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`R1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BFR93`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`R2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BFR93A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`R4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BFR93R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`R5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BFR93AR`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`S`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BB515 (SIE)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`S`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SC3082K`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`S`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SC4619 (EM3)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`S`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SC4100 (UMT)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`S1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BBY31`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`S2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BBY40`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`S6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BF510`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`S7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BF511`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`S8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BF512`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`S9`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BF513`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BSS123LT1`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SAs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BSS123`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SDs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BSS284`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BSS84`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SRs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BSS131`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SS`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BSS138`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`sSG`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SN7002`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`T`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BB811 (SIE)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`T`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SC3906K`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`T`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SC4102 (UMT)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`T0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TP0610T`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`T1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCX17`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`T2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCX18`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`T3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BSS63`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`T4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCX17R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`T5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCX18R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`T6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BSS63R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`T7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BSR15`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`T8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BSR16`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`T9`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BSR18`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`T71`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BSR15R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`T81`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BSR16R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`T91`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BSR18R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`T92`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BSR18A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`T93`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BSR18AR`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`U`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SB852K`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`U1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCXl9`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`U2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCX20`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`U3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BSS64`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`U4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCX19R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`U5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BCX20R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`U6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BSS64R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`U7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BSR13`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`U8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BSR14`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`U81`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BSR17`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`U9`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BSR14R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`U91`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BSR17R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`U92`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BSR17A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`U93`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BSR17AR`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`UB`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SB852K (ROHM)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`V2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BFQ67`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`V3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BFG67`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`V4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BFT25R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SD1383K`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`W1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BFT92`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`W4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BFT92R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`X1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BFT93`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`X4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BFT93R`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SD1484K`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2SD1949 (UMT)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`ZA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`RU101`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      