import React from 'react'
import Slider from 'react-slick'
import ProductCard from '../Card'
import imageSearch from './imageSearch'
import { FaArrowCircleLeft, FaArrowCircleRight } from 'react-icons/fa'

const settings = {
  dots: true,
  arrows: true,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  prevArrow: <FaArrowCircleLeft />,
  nextArrow: <FaArrowCircleRight />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
}

const CardSlider = ({ products, data, variant }) => {
  let productImg = []
  let image

  return (
    <Slider {...settings}>
      {products.map(product => {
        productImg = product.image.split('/')
        productImg = productImg[productImg.length - 1].split('.')
        image = imageSearch(productImg[0], data)

        return (
          <ProductCard imageObject={image} variant={variant} {...product} />
        )
      })}
    </Slider>
  )
}

export default CardSlider
