import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "EIA Marking Code for Surface Mound SMD Resistors",
  "slug": "/articles/resistors-made-easy/surface-mount-eia-markings",
  "category": "Articles",
  "tags": [],
  "date": "2017-04-14T00:00:00.000Z",
  "thumbnail": null,
  "thumbnailText": "TODO",
  "originalUrl": "http://www.talkingelectronics.com/projects/ResistorsMadeEasy/SMD-Resistors-EIA-Markings.html",
  "author": "Colin Mitchell",
  "private": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`01R = 1R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`01S = 10R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`01A = 100R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`01B = 1k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`01C = 10k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`01D = 100k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`01E = 1M`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`01F = 10M`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`02R = 1R02`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`02S = 10R2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`02A = 102R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`02B = 1k02`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`02C = 10k2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`02D = 102k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`02E = 1M02`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`03R = 1R05`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`03S = 10R5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`03A = 105R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`03B = 1k05`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`03C = 10k5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`03D = 105k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`03E = 1M05`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`18F = 15M`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`04R = 1R07`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`04S = 10R7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`04A = 107R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`04B = 1k07`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`04C = 10k7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`04D = 107k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`04E = 1M07`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`05R = 1R1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`05S = 11R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`05A = 110R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`05B = 1k1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`05C = 11k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`05D = 110k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`05E = 1M1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`30F = 20M`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`06R = 1R13`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`06S = 11R3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`06A = 113R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`06B = 1k13`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`06C = 11k3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`06D = 113k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`06E = 1M13`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`07R = 1R15`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`07S = 11R5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`07A = 115R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`07B = 1k15`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`07C = 11k5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`07D = 115k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`07E = 1M15`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`08R = 1R18`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`08S = 11R8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`08A = 118R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`08B = 1k18`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`08C = 11k8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`08D = 118k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`08E = 1M18`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`09R = 1R21`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`09S = 12R1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`09A = 121R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`09B = 1k21`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`09C = 12k1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`09D = 121k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`09E = 1M21`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`10R = 1R24`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10S = 12R4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10A = 124R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10B = 1k24`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10C = 12k4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10D = 124k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10E = 1M24`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`11R = 1R27`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`11S = 12R7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`11A = 127R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`11B = 1k27`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`11C = 12k7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`11D = 127k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`11E = 1M27`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`12R = 1R3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12S = 13R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12A = 130R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12B = 1k3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12C = 13k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12D = 130k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12E = 1M3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`13R = 1R33`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`13S = 13R3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`13A = 133R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`13B = 1k33`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`13C = 13k3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`13D = 133k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`13E = 1M33`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`14R = 1R37`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14S = 13R7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14A = 137R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14B = 1k37`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14C = 13k7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14D = 137k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14E = 1M37`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`15R = 1R4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`15S = 14R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`15A = 140R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`15B = 1k4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`15C = 14k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`15D = 140k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`15E = 1M4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`16R = 1R43`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16S = 14R3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16A = 143R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16B = 1k43`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16C = 14k3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16D = 143k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16E = 1M43`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`17R = 1R47`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`17S = 14R7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`17A = 147R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`17B = 1k47`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`17C = 14k7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`17D = 147k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`17E = 1M47`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`18R = 1R5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`18S = 15R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`18A = 150R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`18B = 1k5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`18C = 15k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`18D = 150k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`18E = 1M5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`19R = 1R54`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`19S = 15R4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`19A = 154R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`19B = 1k54`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`19C = 15k4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`19D = 154k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`19E = 1M54`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`20R = 1R58`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`20S = 15R8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`20A = 158R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`20B = 1k58`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`20C = 15k8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`20D = 158k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`20E = 1M58`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`21R = 1R62`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`21S = 16R2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`21A = 162R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`21B = 1k62`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`21C = 16k2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`21D = 162k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`21E = 1M62`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`22R = 1R65`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`22S = 16R5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`22A = 165R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`22B = 1k65`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`22C = 16k5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`22D = 165k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`22E = 1M65`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`23R = 1R69`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`23S = 16R9`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`23A = 169R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`23B = 1k69`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`23C = 16k9`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`23D = 169k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`23E = 1M69`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`24R = 1R74`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`24S = 17R4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`24A = 174R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`24B = 1k74`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`24C = 17k4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`24D = 174k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`24E = 1M74`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`25R = 1R78`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`25S = 17R8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`25A = 178R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`25B = 1k78`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`25C = 17k8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`25D = 178k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`25E = 1M78`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`26R = 1R82`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`26S = 18R2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`26A = 182R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`26B = 1k82`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`26C = 18k2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`26D = 182k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`26E = 1M82`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`27R = 1R87`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`27S = 18R7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`27A = 187R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`27B = 1k87`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`27C = 18k7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`27D = 187k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`27E = 1M87`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`28R = 1R91`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`28S = 19R1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`28A = 191R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`28B = 1k91`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`28C = 19k1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`28D = 191k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`28E = 1M91`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`29R = 1R96`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`29S = 19R6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`29A = 196R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`29B = 1k96`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`29C = 19k6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`29D = 196k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`29E = 1M96`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`30R = 2R0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`30S = 20R0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`30A = 200R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`30B = 2k0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`30C = 20k0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`30D = 200k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`30E = 2M0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`31R = 2R05`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`31S = 20R5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`31A = 205R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`31B = 2k05`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`31C = 20k5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`31D = 205k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`31E = 2M05`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`32R = 2R10`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`32S = 21R0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`32A = 210R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`32B = 2k10`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`32C = 21k0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`32D = 210k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`32E = 2M10`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`33R = 2R15`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`33S = 21R5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`33A = 215R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`33B = 2k15`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`33C = 21k5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`33D = 215k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`33E = 2M15`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`34R = 2R21`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`34S = 22R1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`34A = 221R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`34B = 2k21`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`34C = 22k1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`34D = 221k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`34E = 2M21`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`35R = 2R26`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`35S = 22R6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`35A = 226R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`35B = 2k26`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`35C = 22k6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`35D = 226k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`35E = 2M26`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`36R = 2R32`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`36S = 23R2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`36A = 232R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`36B = 2k32`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`36C = 23k2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`36D = 232k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`36E = 2M32`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`37R = 2R37`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`37S = 23R7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`37A = 237R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`37B = 2k37`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`37C = 23k7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`37D = 237k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`37E = 2M37`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`38R = 2R43`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`38S = 24R3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`38A = 243R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`38B = 2k43`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`38C = 24k3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`38D = 243k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`38E = 2M43`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`39R = 2R49`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`39S = 24R9`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`39A = 249R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`39B = 2k49`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`39C = 24k9`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`39D = 249k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`39E = 2M49`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`40R = 2R55`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40S = 25R5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40A = 255R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40B = 2k55`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40C = 25k5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40D = 255k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`40E = 2M55`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`41R = 2R61`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`41S = 26R1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`41A = 261R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`41B = 2k61`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`41C = 26k1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`41D = 261k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`41E = 2M61`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`42R = 2R67`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`42S = 26R7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`42A = 267R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`42B = 2k67`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`42C = 26k7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`42D = 267k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`42E = 2M67`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`43R = 2R74`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`43S = 27R4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`43A = 274R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`43B = 2k74`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`43C = 27k4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`43D = 274k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`43E = 2M74`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`44R = 2R80`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`44S = 28R0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`44A = 280R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`44B = 2k80`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`44C = 28k0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`44D = 280k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`44E = 2M80`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`45R = 2R87`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`45S = 28R7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`45A = 287R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`45B = 2k87`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`45C = 28k7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`45D = 287k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`45E = 2M87`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`46R = 2R94`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`46S = 29R4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`46A = 294R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`46B = 2k94`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`46C = 29k4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`46D = 294k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`46E = 2M94`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`47R = 3R01`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`47S = 30R1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`47A = 301R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`47B = 3k01`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`47C = 30k1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`47D = 301k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`47E = 3M01`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`48R = 3R09`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`48S = 30R9`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`48A = 309R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`48B = 3k09`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`48C = 30k9`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`48D = 309k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`48E = 3M09`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`49R = 3R16`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`49S = 31R6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`49A = 316R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`49B = 3k16`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`49C = 31k6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`49D = 316k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`49E = 3M16`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`50R = 3R24`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`50S = 32R4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`50A = 324R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`50B = 3k24`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`50C = 32k4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`50D = 324k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`50E = 3M24`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`51R = 3R32`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`51S = 33R2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`51A = 332R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`51B = 3k32`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`51C = 33k2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`51D = 332k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`51E = 3M32`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`52R = 3R4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`52S = 34R0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`52A = 340R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`52B = 3k4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`52C = 34k0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`52D = 340k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`52E = 3M4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`53R = 3R48`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`53S = 34R8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`53A = 348R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`53B = 3k48`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`53C = 34k8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`53D = 348k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`53E = 3M48`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`54R = 3R57`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`54S = 35R7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`54A = 357R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`54B = 3k57`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`54C = 35k7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`54D = 357k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`54E = 3M57`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`55R = 3R65`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`55S = 36R5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`55A = 365R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`55B = 3k65`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`55C = 36k5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`55D = 365k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`55E = 3M65`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`56R = 3R74`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`56S = 37R4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`56A = 374R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`56B = 3k74`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`56C = 37k4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`56D = 374k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`56E = 3M74`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`57R = 3R83`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`57S = 38R3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`57A = 383R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`57B = 3k83`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`57C = 38k3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`57D = 383k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`57E = 3M83`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`58R = 3R92`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`58S = 39R2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`58A = 392R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`58B = 3k92`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`58C = 39k2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`58D = 392k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`58E = 3M92`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`59R = 4R02`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`59S = 40R2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`59A = 402R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`59B = 4k02`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`59C = 40k2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`59D = 402k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`59E = 4M02`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`60R = 4R12`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`60S = 41R2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`60A = 412R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`60B = 4k12`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`60C = 41k2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`60D = 412k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`60E = 4M12`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`61R = 4R22`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`61S = 42R2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`61A = 422R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`61B = 4k22`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`61C = 42k2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`61D = 422k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`61E = 4M22`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`62R = 4R32`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`62S = 43R2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`62A = 432R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`62B = 4k32`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`62C = 43k2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`62D = 432k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`62E = 4M32`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`63R = 4R42`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`63S = 44R2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`63A = 442R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`63B = 4k42`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`63C = 44k2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`63D = 442k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`63E = 4M42`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`64R = 4R53`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`64S = 45R3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`64A = 453R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`64B = 4k53`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`64C = 45k3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`64D = 453k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`64E = 4M53`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`65R = 4R64`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`65S = 46R4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`65A = 464R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`65B = 4k64`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`65C = 46k4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`65D = 464k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`65E = 4M64`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`66R = 4R75`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`66S = 47R5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`66A = 475R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`66B = 4k75`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`66C = 47k5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`66D = 475k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`66E = 4M75`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`67R = 4R87`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`67S = 48R7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`67A = 487R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`67B = 4k87`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`67C = 48k7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`67D = 487k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`67E = 4M87`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`68R = 4R99`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`68S = 49R9`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`68A = 499R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`68B = 4k99`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`68C = 49k9`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`68D = 499k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`68E = 4M99`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`69R = 5R11`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`69S = 51R1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`69A = 511R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`69B = 5k11`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`69C = 51k1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`69D = 511k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`69E = 5M11`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`70R = 5R23`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`70S = 52R3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`70A = 523R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`70B = 5k23`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`70C = 52k3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`70D = 523k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`70E = 5M23`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`71R = 5R36`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`71S = 53R6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`71A = 536R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`71B = 5k36`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`71C = 53k6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`71D = 536k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`71E = 5M36`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`72R = 5R49`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`72S = 54R9`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`72A = 549R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`72B = 5k49`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`72C = 54k9`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`72D = 549k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`72E = 5M49`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`73R = 5R62`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`73S = 56R2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`73A = 562R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`73B = 5k62`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`73C = 56k2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`73D = 562k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`73E = 5M62`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`74R = 5R76`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`74S = 57R6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`74A = 576R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`74B = 5k76`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`74C = 57k6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`74D = 576k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`74E = 5M76`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`75R = 5R9`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`75S = 59R0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`75A = 590R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`75B = 5k9`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`75C = 59k0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`75D = 590k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`75E = 5M9`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`76R = 6R04`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`76S = 60R4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`76A = 604R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`76B = 6k04`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`76C = 60k4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`76D = 604k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`76E = 6M04`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`77R = 6R19`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`77S = 61R9`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`77A = 619R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`77B = 6k19`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`77C = 61k9`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`77D = 619k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`77E = 6M19`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`78R = 6R34`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`78S = 63R4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`78A = 634R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`78B = 6k34`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`78C = 63k4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`78D = 634k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`78E = 6M34`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`79R = 6R49`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`79S = 64R9`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`79A = 649R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`79B = 6k49`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`79C = 64k9`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`79D = 649k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`79E = 6M49`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`80R = 6R65`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`80S = 66R5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`80A = 665R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`80B = 6k65`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`80C = 66k5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`80D = 665k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`80E = 6M65`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`81R = 6R81`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`81S = 68R1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`81A = 681R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`81B = 6k81`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`81C = 68k1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`81D = 681k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`81E = 6M81`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`82R = 6R98`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`82S = 69R8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`82A = 698R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`82B = 6k98`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`82C = 69k8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`82D = 698k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`82E = 6M98`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`83R = 7R15`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`83S = 71R5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`83A = 715R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`83B = 7k15`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`83C = 71k5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`83D = 715k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`83E = 7M15`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`84R = 7R32`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`84S = 73R2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`84A = 732R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`84B = 7k32`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`84C = 73k2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`84D = 732k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`84E = 7M32`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`85R = 7R5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`85S = 75R0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`85A = 750R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`85B = 7k5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`85C = 75k0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`85D = 750k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`85E = 7M5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`86R = 7R68`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`86S = 76R8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`86A = 768R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`86B = 7k68`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`86C = 76k8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`86D = 768k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`86E = 7M68`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`87R = 7R87`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`87S = 78R7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`87A = 787R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`87B = 7k87`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`87C = 78k7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`87D = 787k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`87E = 7M87`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`88R = 8R06`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`88S = 80R6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`88A = 806R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`88B = 8k06`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`88C = 80k6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`88D = 806k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`88E = 8M06`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`89R = 8R25`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`89S = 82R5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`89A = 825R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`89B = 8k25`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`89C = 82k5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`89D = 825k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`89E = 8M25`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`90R = 8R45`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`90S = 84R5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`90A = 845R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`90B = 8k45`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`90C = 84k5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`90D = 845k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`90E = 8M45`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`91R = 8R66`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`91S = 86R6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`91A = 866R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`91B = 8k66`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`91C = 86k6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`91D = 866k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`91E = 8M66`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`92R = 8R87`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`92S = 88R7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`92A = 887R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`92B = 8k87`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`92C = 88k7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`92D = 887k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`92E = 8M87`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`93R = 9R09`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`93S = 90R9`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`93A = 909R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`93B = 9k09`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`93C = 90k9`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`93D = 909k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`93E = 9M09`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`94R = 9R31`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`94S = 93R1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`94A = 931R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`94B = 9k31`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`94C = 93k1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`94D = 931k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`94E = 9M31`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`95R = 9R53`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`95S = 95R3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`95A = 953R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`95B = 9k53`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`95C = 95k3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`95D = 953k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`95E = 9M53`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`96R = 9R76`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`96S = 97R6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`96A = 976R`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`96B = 9k76`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`96C = 97k6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`96D = 976k`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`96E = 9M76`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      