import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "PIC16F84A Instructions",
  "slug": "/articles/chip-data-ebook-1d/pic16f84a-instructions",
  "category": "Articles",
  "tags": [],
  "date": null,
  "thumbnail": null,
  "thumbnailText": "TODO",
  "originalUrl": "http://www.talkingelectronics.com/ChipDataEbook-1d/html/PICF84Instructions.html",
  "author": "Colin Mitchell",
  "private": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><img parentName="p" {...{
        "src": "/5b5298d8af4b4efcca0ac8d990006ded/Instruction-Set-F84.gif",
        "alt": null,
        "title": "Instruction Set F84"
      }}></img></p>
    <h2 {...{
      "id": "additional-instructions"
    }}>{`ADDITIONAL INSTRUCTIONS`}</h2>
    <p><inlineCode parentName="p">{`ADDWF PCL,1 ADDWF 02,1`}</inlineCode>{` Alter the program counter to create a jump`}<br parentName="p"></br>{`
`}<inlineCode parentName="p">{`CLRF INDF,1 CLRF 00,1`}</inlineCode>{` Clear INDIRECT file`}<br parentName="p"></br>{`
`}<inlineCode parentName="p">{`INCF FSR,1 INCF 04,1`}</inlineCode>{` Increment the File Select Register`}</p>
    <p><inlineCode parentName="p">{`INTCON`}</inlineCode>{` address is either `}<inlineCode parentName="p">{`0Bh`}</inlineCode>{` or `}<inlineCode parentName="p">{`8Bh`}</inlineCode>{` - it is mapped at both locations.`}<br parentName="p"></br>{`
`}<inlineCode parentName="p">{`BCF INTCON,RBIF`}</inlineCode>{` Clear RB<7:4> Port-change Interrupt flag`}<br parentName="p"></br>{`
`}<inlineCode parentName="p">{`BSF INTCON,RBIF`}</inlineCode>{` Set RB<7:4> Port-change Interrupt flag`}<br parentName="p"></br>{`
`}<inlineCode parentName="p">{`BCF INTCON,RBIE`}</inlineCode>{` Disables the Port-change Interrupt`}<br parentName="p"></br>{`
`}<inlineCode parentName="p">{`BSF INTCON,RBIE`}</inlineCode>{` Enables the Port-change Interrupt`}<br parentName="p"></br>{`
`}<inlineCode parentName="p">{`BCF INTCON,INTE`}</inlineCode>{` Disables the RB0/INT Interrupt`}<br parentName="p"></br>{`
`}<inlineCode parentName="p">{`BSF INTCON,INTE`}</inlineCode>{` Enables the RB0/INT Interrupt`}<br parentName="p"></br>{`
`}<inlineCode parentName="p">{`BCF INTCON,T0IE`}</inlineCode>{` Disables the TMR0 Interrupt`}<br parentName="p"></br>{`
`}<inlineCode parentName="p">{`BSF INTCON,T0IE`}</inlineCode>{` Enables the TMR0 Interrupt`}<br parentName="p"></br>{`
`}<inlineCode parentName="p">{`BCF INTCON,EEIE`}</inlineCode>{` Disables the EE write complete Interrupt`}<br parentName="p"></br>{`
`}<inlineCode parentName="p">{`BSF INTCON,EEIE`}</inlineCode>{` Enables the EE write complete Interrupt`}<br parentName="p"></br>{`
`}<inlineCode parentName="p">{`BCF INTCON,GIE`}</inlineCode>{` Disables all Interrupts`}<br parentName="p"></br>{`
`}<inlineCode parentName="p">{`BSF INTCON,GIE`}</inlineCode>{` Enables all un-masked Interrupts`}</p>
    <p>{`OPTION address is 81h - use the word OPTION`}<br parentName="p"></br>{`
`}{`OPTION,0 OPTION,1 OPTION,2`}</p>
    <p><strong parentName="p">{`Prescaler Rate Select Bits`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Bit Value`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TMR0 Rate`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`WDT Rate`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`000`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1:2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1:1`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`001`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1:4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1:2`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`010`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1:8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1:4`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`011`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1:16`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1:8`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`100`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1:32`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1:16`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`101`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1:64`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1:32`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`110`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1:128`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1:64`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`111`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1:256`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1:128`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`e:g:`}<br parentName="p"></br>{`
`}{`BSF OPTION,0 BCF OPTION,1 BSF OPTION,2 TMR0 Rate = 1:64 WDT Rate = 1:32`}<br parentName="p"></br>{`
`}{`BSF OPTION,0 BSF OPTION,1 BSF OPTION,2 TMR0 Rate = 1:256 WDT Rate = 1:128`}</p>
    <p><inlineCode parentName="p">{`BCF OPTION,PSA BCF OPTION,3`}</inlineCode>{` Prescaler assigned to TMR0`}<br parentName="p"></br>{`
`}<inlineCode parentName="p">{`BSF OPTION,PSA BSF OPTION,3`}</inlineCode>{` Prescaler assigned to the WDT`}<br parentName="p"></br>{`
`}<inlineCode parentName="p">{`BCF OPTION,T0SE BCF OPTION,4`}</inlineCode>{` Increment on low-to-high on RA4/T0CKI pin`}<br parentName="p"></br>{`
`}<inlineCode parentName="p">{`BSF OPTION,T0SE BSF OPTION,4`}</inlineCode>{` Increment on high-to-low on RA4/T0CKI pin`}<br parentName="p"></br>{`
`}<inlineCode parentName="p">{`BCF OPTION,T0CS BCF OPTION,5`}</inlineCode>{` Internal Instruction cycle clock (CLKOUT)`}<br parentName="p"></br>{`
`}<inlineCode parentName="p">{`BSF OPTION,T0CS BSF OPTION,5`}</inlineCode>{` Clock source select. Transition on RA4/T0CKI Pin`}<br parentName="p"></br>{`
`}<inlineCode parentName="p">{`BCF OPTION,INTEDG BCF OPTION,6`}</inlineCode>{` Interrupt on falling edge of RB0/INT Pin`}<br parentName="p"></br>{`
`}<inlineCode parentName="p">{`BSF OPTION,INTEDG BSF OPTION,6`}</inlineCode>{` Interrupt on rising edge of RB0/INT Pin`}<br parentName="p"></br>{`
`}<inlineCode parentName="p">{`BCF OPTION,RBPU BCF OPTION,7`}</inlineCode>{` PORT B Pull-ups are enabled`}<br parentName="p"></br>{`
`}<inlineCode parentName="p">{`BSF OPTION,RBPU BSF OPTION,7`}</inlineCode>{` PORT B Pull-ups are disabled`}</p>
    <p><inlineCode parentName="p">{`STATUS`}</inlineCode>{` address is either `}<inlineCode parentName="p">{`03h`}</inlineCode>{` or `}<inlineCode parentName="p">{`83h`}</inlineCode>{` - it is mapped at both locations.`}<br parentName="p"></br>{`
`}<inlineCode parentName="p">{`BTFSS STATUS,C or BTFSC 03,0`}</inlineCode>{` Test the carry bit. C=1=set = carry occurred`}<br parentName="p"></br>{`
`}<inlineCode parentName="p">{`BTFSS STATUS,C or BTFSC 03,0`}</inlineCode>{` Test the carry bit. C=0=reset = carry did not occur`}<br parentName="p"></br>{`
`}<inlineCode parentName="p">{`BTFSS STATUS,DC BTFSS 03,1`}</inlineCode>{` Test the digit carry bit. C=1=set = carry-out from the 4th lower order bit of the result occurred.`}<br parentName="p"></br>{`
`}<inlineCode parentName="p">{`BTFSS STATUS,DC BTFSS 03,1`}</inlineCode>{` Test the digit carry bit. C=0=reset = carry-out did not occur from the 4th lower order bit of the result.`}<br parentName="p"></br>{`
`}<inlineCode parentName="p">{`BTFSS STATUS,Z or BTFSC 03,2`}</inlineCode>{` Test the zero bit. Z=1=set = result of arithmetic or logic operation is zero.`}<br parentName="p"></br>{`
`}<inlineCode parentName="p">{`BTFSS STATUS,Z or BTFSC 03,2`}</inlineCode>{` Test the zero bit. Z=0=reset = result of arithmetic or logic operation is not zero.`}<br parentName="p"></br>{`
`}<inlineCode parentName="p">{`BSF STATUS,RP0 BSF 03,5`}</inlineCode>{` Go to Bank1 for TRISA or TRISB register`}<br parentName="p"></br>{`
`}<inlineCode parentName="p">{`BCF STATUS,RP0 BCF 03,5`}</inlineCode>{` Go to Bank0 for programming`}</p>
    <p><inlineCode parentName="p">{`CLRF INDF,1 CLRF 00,1`}</inlineCode>{` Clear INDIRECT file`}</p>
    <p><inlineCode parentName="p">{`RETFIE`}</inlineCode>{` Return from Interrupt Sets `}<inlineCode parentName="p">{`INTCON,GIE`}</inlineCode></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`File name:`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`File No:`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Comments:`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`EECON1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`equ 088h`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`;EEPROM Control Register 1`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`EECON2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`equ 089h`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`;EEPROM Control Register 2`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`EEADR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`equ 9`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`;EEPROM address Register`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`EEDATA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`equ 8`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`;EEPROM Data Register`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`FSR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`equ 4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`;File Select Register`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`INDF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`equ 0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`;INDIRECT file`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`INTCON`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`equ 0Bh`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`;Interrupt and Timer bits`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`OPTION`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`equ 081h`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`;Option Register`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`PCL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`equ 2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`;Program Counter Low-bits`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`PORTA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`equ 5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`;Port A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`PORTB`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`equ 6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`;Port B`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`STATUS`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`equ 3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`;Status Register`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`TRISA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`equ 085h`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`;Make Port A bits input or output`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`TRISB`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`equ 086h`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`;Make Port B bits input or output`}</td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Bit name:`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Bit No:`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Comments and name of file holding the bit:`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Carry`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`equ 0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`;Carry bit in Status file C=0=no carry-out`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`DC`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`equ 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`;Digit Carry in Status DC=0=no carry out from 4th low-order digit`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`EEIF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`equ 4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`;In EECON1 EEIF=1=The write operation completed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`f`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`equ 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`;Place the result of the operation in the file`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`GIE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`equ 7`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`;Global Interrupt Enable bit. In INTCON 0=Disables all interrupts`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`RD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`equ 0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`;In EECON1 Read Control bit`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`RP0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`equ 5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`;In Status. Register Bank select RP0=clear=bank0 RP0=set=bank1`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Self`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`equ 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`;Store the result in the file`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`T0IE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`equ 5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`;In INTCON Timer0 Overflow Interrupt Enable bit`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`T0IF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`equ 2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`;In INTCON Timer0 Overflow Interrupt Flag bit`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`equ 0`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`;Place the result in W`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`WR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`equ 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`;In EECON1 Write Control bit`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`WREN`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`equ 2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`;In EECON1 EEPROM Write Enable bit`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Z`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`equ 2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`;Z in Status file. Zero flag=set=1 when result of operation is zero!!`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      