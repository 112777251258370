import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BecPicProgramList from '@posts/articles/_bec-pic-program-list/index.mdx';
export const _frontmatter = {
  "title": "PIC Lab 1 Content Of Articles",
  "slug": "/articles/pic-lab-1-content-of-articles",
  "category": "Articles",
  "originalUrl": "http://www.talkingelectronics.com/FrameworkPages/cont05b-PIC_LAB-1.html",
  "author": "Colin Mitchell",
  "private": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "contents-of-articles"
    }}>{`Contents of Articles`}</h1>

    <BecPicProgramList mdxType="BecPicProgramList" />
    <h2 {...{
      "id": "main-links"
    }}>{`main links`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "../Buy_Kit/Buy-Pic_Lab-1.html"
        }}>{`buy PIC_LAB-1 kit`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "../BuyReadyBuilt/Buy-Pgmr_Lab-1.html"
        }}>{`Buy Ready Built`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "../poptronics.com/interactive/FreeProjects/PIC_LAB-1/BASIC.html"
        }}>{`BASIC Article`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "../html/IconArticle.html"
        }}>{`Icon Article`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/pic-lab-1/page-10-extra"
        }}>{`Library of Routines in “extra pages”`}</a></li>
    </ul>
    <h2 {...{
      "id": "projects"
    }}>{`projects:`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/pic-lab-1"
        }}>{`PIC LAB-1`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/pic-lab-1"
        }}>{`Intro-top`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/pic-lab-1/page-1-bottom"
        }}>{`Intro-bottom`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/pic-lab-1/test-routine"
        }}>{`Test Routine`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/pic-lab-1/page-1a"
        }}>{`PIC Instructions`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/pic-lab-1/page-1b"
        }}>{`HEX Values`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/pic-lab-1/all-files-needed-for-experiments"
        }}>{`All expt files`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/pic-lab-1/page-2"
        }}>{`Experiments 1 to 3`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/pic-lab-1/page-3"
        }}>{`Experiments 4 to 6`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/pic-lab-1/page-4"
        }}>{`Experiments 7 to 11a`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/pic-lab-1/page-5"
        }}>{`Experiments 11b to 12`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/pic-lab-1/page-5a"
        }}>{`21 Matches`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/pic-lab-1/rock-paper-scissors"
        }}>{`Rock Paper Scissors`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/pic-lab-1/reader-experiments"
        }}>{`Readers Experiments`}</a></li>
    </ul>
    <h2 {...{
      "id": "extra-pages"
    }}>{`Extra Pages`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/pic-lab-1/extra-pages"
        }}>{`Index`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/pic-lab-1/page-6-extra"
        }}>{`Piezo`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/pic-lab-1/page-7-extra"
        }}>{`Nitinol Wire`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/pic-lab-1/page-8-extra"
        }}>{`Going Further`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/pic-lab-1/page-9-extra"
        }}>{`3-Digit Counter & AtoD`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/pic-lab-1/page-10-extra"
        }}>{`Library of Routines`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/pic-lab-1/files/Lib-1.asm"
        }}>{`Copy & Save Routines`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/pic-lab-1/page-11-extra"
        }}>{`Timing and Delays`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/pic-lab-1/page-12-extra"
        }}>{`Adding extra In/Out`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/pic-lab-1/page-13-extra"
        }}>{`Advanced Programming`}</a></li>
    </ul>
    <h2 {...{
      "id": "tests"
    }}>{`Tests`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/pic-lab-1/page-14-test"
        }}>{`Test 1`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/pic-lab-1/page-15-test"
        }}>{`Test 2`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/pic-lab-1/page-16-test"
        }}>{`Test 3`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/pic-lab-1/page-17-test"
        }}>{`Test 4`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/pic-lab-1/page-18-test"
        }}>{`Test 5`}</a></li>
      <li parentName="ul">{`Page 19 More to be added here`}</li>
    </ul>
    <undefined><img {...{
        "alt": "Use this Map for guidance",
        "border": 0,
        "height": 150,
        "src": "/1ecbedc5cd7f55b645c969134952ab78/MapLab-1.gif",
        "useMap": "#MapLab1",
        "width": 450
      }}></img>
      <map {...{
        "name": "MapLab1"
      }}>{`
  `}<area parentName="map" {...{
          "coords": [16, 49, 121, 96],
          "href": "/articles/pic-lab-1",
          "shape": "RECT",
          "alt": ""
        }}></area>{`
  `}<area parentName="map" {...{
          "coords": [11, 112, 121, 137],
          "href": "/articles/pic-lab-1/test-routine",
          "shape": "RECT",
          "alt": ""
        }}></area>{`
  `}<area parentName="map" {...{
          "coords": [150, 11, 272, 31],
          "href": "/articles/pic-lab-1/page-2",
          "shape": "RECT",
          "alt": ""
        }}></area>{`
  `}<area parentName="map" {...{
          "coords": [151, 38, 272, 60],
          "href": "/articles/pic-lab-1/page-3",
          "shape": "RECT",
          "alt": ""
        }}></area>{`
  `}<area parentName="map" {...{
          "coords": [152, 64, 271, 85],
          "href": "/articles/pic-lab-1/page-4",
          "shape": "RECT",
          "alt": ""
        }}></area>{`
  `}<area parentName="map" {...{
          "coords": [151, 91, 275, 114],
          "href": "/articles/pic-lab-1/page-5",
          "shape": "RECT",
          "alt": ""
        }}></area>{`
  `}<area parentName="map" {...{
          "coords": [152, 119, 276, 139],
          "href": "/articles/pic-lab-1/extra-pages",
          "shape": "RECT",
          "alt": ""
        }}></area>{`
  `}<area parentName="map" {...{
          "coords": [318, 14, 408, 50],
          "href": "/articles/pic-lab-1/reader-experiments",
          "shape": "RECT",
          "alt": ""
        }}></area>{`
  `}<area parentName="map" {...{
          "coords": [309, 57, 416, 79],
          "href": "/articles/pic-lab-1/files/BlankF84.asm",
          "shape": "RECT",
          "alt": ""
        }}></area>{`
  `}<area parentName="map" {...{
          "coords": [333, 87, 393, 109],
          "href": "/articles/in-circuit-programming/files/MPASM/MPASMWIN.rar",
          "shape": "RECT",
          "alt": ""
        }}></area>{`
  `}<area parentName="map" {...{
          "coords": [309, 111, 415, 133],
          "href": "/articles/pic-lab-1/files/PIC16F84A.pdf",
          "shape": "RECT",
          "alt": ""
        }}></area>
      </map></undefined>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      