import React from 'react'
import { Box, Text } from 'theme-ui'

const styles = {
  description: {
    flex: `auto`,
    textAlign: `left`,
    mb: 0
  }
}

const ProductDescription = ({ description }) => {
  // by default we'll output the text as is.
  let text = (
    <Text variant='small' sx={styles.description}>
      {description}
    </Text>
  )

  // if the text is in an array of text, then we will iterate over it instead.
  if (typeof description === 'object') {
    text = description.map(desc => {
      return (
        <Text variant='small' sx={styles.description}>
          {desc}
        </Text>
      )
    })
  }

  return <Box>{text}</Box>
}

export default ProductDescription
