import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Topics",
  "slug": "/articles/the-transistor-amplifier",
  "private": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`TOPICS:`}</p>
    <p>{`[Adjustable Current Power Supply]`}{`(/articles/the-transistor-amplifier/page-2#Adjustable Current)`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier#Adjusting"
      }}>{`Adjusting The Stage Gain`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#Detector"
      }}>{`AF Detector`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#Analogue-and-Digital"
      }}>{`ANALOGUE and DIGITAL mode`}</a>{` Read this section to see what we mean`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#Analogue"
      }}>{`Analogue To Digital`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#GATES"
      }}>{`AND Gate`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier#Stage"
      }}>{`A “Stage”`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#BackEMF"
      }}>{`Back EMF`}</a><br parentName="p"></br>{`
`}{`[Base Bias]`}{`(/articles/the-transistor-amplifier#Base Bias)`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#Vbe"
      }}>{`Base-emitter Voltage`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#Biasing"
      }}>{`Biasing A Transistor`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#Fig105"
      }}>{`Biasing Diodes in push Pull Amplifier`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#BaseBias-1"
      }}>{`Biasing the base`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#BLOCKING"
      }}>{`Blocking Oscillator`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier#BRIDGE"
      }}>{`Bridge`}</a>{` - the`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#Boost"
      }}>{`Boost Converter`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier#Bootstrap"
      }}>{`Bootstrap Circuit`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "#Breakdown"
      }}>{`Breakdown`}</a>{` - Transistor Breakdown`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#Buck"
      }}>{`Buck Converter`}</a>{` - the`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#Buffer"
      }}>{`Buffer`}</a>{` - The Transistor Buffer`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier#TheCapacitor"
      }}>{`Capacitor`}</a>{` - The `}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier#TheCapacitor"
      }}>{`Capacitor passing a spike or signal`}</a>{` - `}<a parentName="p" {...{
        "href": "/articles/how-a-capacitor-works"
      }}>{`How the capacitor works`}</a><br parentName="p"></br>{`
`}{`[Capacitor Coupling]`}{`(/articles/the-transistor-amplifier/page-2#Coupling Cap)`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#Flyback"
      }}>{`CFL Driver`}</a>{` - flyback Oscillator`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#HigherGain"
      }}>{`Changing A Transistor`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#Class-A"
      }}>{`Class-A -B and -C`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#Distortion"
      }}>{`Clipping and Distortion`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier#TotemPole"
      }}>{`Collector Open`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#MORE"
      }}>{`Colpitts Oscillator`}</a><br parentName="p"></br>{`
`}{`[Common Base Amplifier]`}{`(/articles/the-transistor-amplifier#COMMON BASE AMPLIFIER)`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#CommonCollector"
      }}>{`Common Collector`}</a>{` - Emitter Follower`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#CommonCollectorProblems"
      }}>{`Common-Collector Problems`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#Configurations"
      }}>{`Configurations`}</a>{` - summary of features of Common Emitter, C-Collector, and Common Base`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier#Stage"
      }}>{`Common Emitter with Self-Bias`}</a>{` - base-bias resistor produces negative feedback`}<br parentName="p"></br>{`
`}{`[Common Emitter stage with fixed base bias]`}{`(/articles/the-transistor-amplifier#Fixed Base Bias)`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier#Connecting"
      }}>{`Connecting 2 Stages`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier#Connecting3Stages"
      }}>{`Connecting 3 Stages`}</a><br parentName="p"></br>{`
`}{`[Constant Current Circuit]`}{`(/articles/the-transistor-amplifier/page-2#Constant Current) - the`}<br parentName="p"></br>{`
`}{`[Constant Voltage Circuit]`}{`(/articles/the-transistor-amplifier/page-2#Constant Voltage) - the`}<br parentName="p"></br>{`
`}{`[Coupling Capacitor]`}{`(/articles/the-transistor-amplifier/page-2#Coupling Cap) - the`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#OutputStage"
      }}>{`Courses available`}</a>{` - see discussion at end of this topic: Designing An Output Stage`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier#CURRENT"
      }}>{`Current`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier#CURRENT"
      }}>{`Current gain of emitter follower stage`}</a><br parentName="p"></br>{`
`}{`[Current Buffer Circuit]`}{`(/articles/the-transistor-amplifier#Current Buffer)`}<br parentName="p"></br>{`
`}{`[Current Source Circuit]`}{`(/articles/the-transistor-amplifier#Current Source)`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#CurrentLimiter"
      }}>{`Current Limiter`}</a>{` `}{`[Current Limited Power Supply]`}{`(/articles/the-transistor-amplifier/page-2#Adjustable Current)`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#C-to-Voltage"
      }}>{`Current to Voltage Converter`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#CurrentMirror"
      }}>{`Current Mirror Circuit`}</a><br parentName="p"></br>{`
`}{`[Darlington]`}{`(/articles/the-transistor-amplifier#THE DARLINGTON) - and the `}{`[Sziklai Pair]`}{`(/articles/the-transistor-amplifier#THE DARLINGTON)`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier#Connecting"
      }}>{`DC (Direct Coupled) Stage`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#Decoupling"
      }}>{`Decoupling`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#Time"
      }}>{`Delay Circuit`}</a>{` (time delay)`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#OutputStage"
      }}>{`Designing an Output Stage`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#DesignValues"
      }}>{`Design Values`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#Design"
      }}>{`Design Your Own Transistor Amplifier`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#Differential"
      }}>{`Differential Amplifier`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#Integration"
      }}>{`Differentiation`}</a><br parentName="p"></br>{`
`}{`[Digital Stage]`}{`(/articles/the-transistor-amplifier/page-2#THE DIGITAL STAGE) - the
`}{`[Digital Transistor]`}{`(/articles/the-transistor-amplifier/page-2#THE DIGITAL STAGE) - the`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#Pump"
      }}>{`Diode Pump`}</a>{` - The - `}<a parentName="p" {...{
        "href": "/articles/how-a-diode-works"
      }}>{`How the DIODE works`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#Distortion"
      }}>{`Distortion for Guitar`}</a>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#Relay"
      }}>{`Drive a Relay`}</a>{` - constant current driving
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier#Connecting"
      }}>{`Direct Coupled Stage`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#Driver"
      }}>{`Driver Stage`}</a>{` - the`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#Distortion"
      }}>{`Distortion and Clipping`}</a><br parentName="p"></br>{`
`}{`[Drawing A Circuit]`}{`(/articles/the-transistor-amplifier#Drawing A Circuit)`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#OutputStage"
      }}>{`Efficiency of a coupling capacitor`}</a>{` … . as low as 8%!!`}<br parentName="p"></br>{`
`}{`[Electronic Filter]`}{`(/articles/the-transistor-amplifier/page-2#Electronic Filter)`}<br parentName="p"></br>{`
`}{`EMF `}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#BackEMF"
      }}>{`Back EMF`}</a><br parentName="p"></br>{`
`}{`[Emitter by-pass capacitor]`}{`(/articles/the-transistor-amplifier/page-2#STAGE GAIN)`}<br parentName="p"></br>{`
`}{`[Emitter Capacitor]`}{`(/articles/the-transistor-amplifier#Emitter Degeneration)`}<br parentName="p"></br>{`
`}{`[Emitter Degeneration]`}{`(/articles/the-transistor-amplifier#Emitter Degeneration) - (aka Emitter Feedback, Emitter Biasing, Emitter By-pass)`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier#CURRENT"
      }}>{`Emitter Follower`}</a><br parentName="p"></br>{`
`}{`[Emitter Resistor]`}{`(/articles/the-transistor-amplifier#Emitter Degeneration) - and emitter capacitor - called Emitter Feedback`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier#Bootstrap"
      }}>{`Feedback`}</a>{` - positive`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier#Feedback"
      }}>{`Feedback`}</a>{` - another feedback circuit
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#FeedbackCapacitor"
      }}>{`Feedback Capacitor`}</a>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#Filters"
      }}>{`Filters`}</a><br parentName="p"></br>{`
`}{`[FlyBack Oscillator]`}{`(/articles/the-transistor-amplifier/page-2#Zener Tester) `}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#Flyback"
      }}>{`FlyBack Oscillator`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#Fuzz"
      }}>{`Fuzz`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier#Gain"
      }}>{`Gain`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#GATES"
      }}>{`Gates`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#Fuzz"
      }}>{`Guitar pre-amplifier`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#MORE"
      }}>{`Hartley Oscillator`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier#HighCurrentDriver"
      }}>{`High Current Driver`}</a>{` - faulty Design`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#HigherGain"
      }}>{`Higher Gain`}</a>{` Using A Transistor with a Higher or Lower Gain`}<br parentName="p"></br>{`
`}{`[High Impedance Circuit]`}{`(/articles/the-transistor-amplifier#High Impedance)`}<br parentName="p"></br>{`
`}{`[High Input Impedance Circuit]`}{`(/articles/the-transistor-amplifier#Super Alpha)`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#Filters"
      }}>{`High-Pass Filter`}</a><br parentName="p"></br>{`
`}{`[High-side Switching]`}{`(/articles/the-transistor-amplifier/page-2#High-side Switching)`}<br parentName="p"></br>{`
`}{`[How an Oscillator Starts]`}{`(/articles/the-transistor-amplifier/page-2#Osc Starts)`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#Hysteresis"
      }}>{`Hysteresis`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#77a"
      }}>{`Illuminating a globe (lamp)`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#IMPEDANCE"
      }}>{`Impedance Matching`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#Circuit7"
      }}>{`Increasing mobile handset volume`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#Input"
      }}>{`Input and Output Impedance`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#Integration"
      }}>{`Integration and Differentiation`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#Interfacing"
      }}>{`Interfacing`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#Inverter"
      }}>{`Inverter`}</a>{` - transistor as an`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#Latch"
      }}>{`Latch Circuit`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#Leakage"
      }}>{`Leakage`}</a>{` - the small leakage current due to combining two or more transistors`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "#Level"
      }}>{`Level Conversion`}</a>{` or LEVEL SHIFTING see also `}<a parentName="p" {...{
        "href": "#Inverter"
      }}>{`Level Shifting via an Inverter`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#77a"
      }}>{`Lighting a globe (lamp)`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier#Linear"
      }}>{`LINER AMPLIFIER`}</a>{` Transistor as a`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#Differential"
      }}>{`Long Tailed Pair`}</a><br parentName="p"></br>{`
`}{`[Low Impedance Circuit]`}{`(/articles/the-transistor-amplifier#Low Impedance)`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#Filters"
      }}>{`Low-Pass Filter`}</a><br parentName="p"></br>{`
`}{`[Low-side Switching]`}{`(/articles/the-transistor-amplifier/page-2#High-side Switching)`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#Motor-boating"
      }}>{`Motor-boating`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#GATES"
      }}>{`NAND Gate`}</a><br parentName="p"></br>{`
`}{`Negative feedback - lots of circuits have negative feedback. See Fig 103cc`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#NegativeFeedback"
      }}>{`Negative Feedback`}</a><br parentName="p"></br>{`
`}{`Negative Voltage - producing a negative voltage`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#NoCurrent"
      }}>{`No Current`}</a>{` - a circuit that takes no current when “sitting around.”`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier#NPN"
      }}>{`NPN Transistor`}</a><br parentName="p"></br>{`
`}{`[NPN/PNP Amplifier]`}{`(/articles/the-transistor-amplifier#NPN/PNP Amp)`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier#TotemPole"
      }}>{`Open Collector`}</a><br parentName="p"></br>{`
`}{`[Oscillator]`}{`(/articles/the-transistor-amplifier/page-2#Osc Starts) How it starts`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier#OSCILLATORS"
      }}>{`Oscillators`}</a>{` `}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#OSCILLATOR"
      }}>{`Oscillators`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#OutputStage"
      }}>{`Output Stage`}</a>{` - Designing`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#PassTransistor"
      }}>{`Pass Transistor`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#SINE-WAVE"
      }}>{`Phase-Shift Oscillator`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier#PNP"
      }}>{`PNP Transistor`}</a><br parentName="p"></br>{`
`}{`Positive Feedback. See Fig 103cc`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#Pot"
      }}>{`Potentiometer`}</a>{` - The`}<br parentName="p"></br>{`
`}{`[Power of a SIGNAL]`}{`(/articles/the-transistor-amplifier#Signal Power)
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#UJT"
      }}>{`Programmable Unijunction Transistor`}</a>{` (PUT)`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#Pull-Up"
      }}>{`Pull-Up and Pull-Down Resistors`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier#PushPull"
      }}>{`Push Pull`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier#Regeneration"
      }}>{`Regeneration`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#Regulator"
      }}>{`Regulator`}</a>{` - transistor`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#Relay"
      }}>{`Relay`}</a>{` - driving a relay`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier#Resistor"
      }}>{`Resistor`}</a>{` - The`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#Saturating"
      }}>{`Saturating a Transistor`}</a><br parentName="p"></br>{`
`}{`[Schmitt Trigger]`}{`(/articles/the-transistor-amplifier/page-2#THE SCHMITT TRIGGER) - the`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#Latch"
      }}>{`SCR`}</a>{` made with transistors`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "#ShortCctCurrent"
      }}>{`“Shoot-Through” Current`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "#ShortCctCurrent"
      }}>{`Short-Circuit Current`}</a><br parentName="p"></br>{`
`}{`[Signal driving power]`}{`(/articles/the-transistor-amplifier#Signal Power)`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#SINE-WAVE"
      }}>{`Sinewave Oscillator`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#SINKING"
      }}>{`Sinking and Sourcing`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#SplitSupply"
      }}>{`Split Supply`}</a>{` - Split Power Supply`}<br parentName="p"></br>{`
`}{`[Square Wave Oscillator]`}{`(/articles/the-transistor-amplifier/page-2#THE SQUARE-WAVE)`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#SquareToSinewave"
      }}>{`Square-wave to Sinewave`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier#Tr-Sub"
      }}>{`Substituting Transistors`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#Switch"
      }}>{`Switch`}</a>{` - The transistor as a Switch`}<br parentName="p"></br>{`
`}{`[Stage Gain]`}{`(/articles/the-transistor-amplifier#STAGE GAIN)`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#Configurations"
      }}>{`Summary`}</a>{` of a transistor connected in common-emitter, common-base and common-collector`}<br parentName="p"></br>{`
`}{`[Super-Alpha Circuit]`}{`(/articles/the-transistor-amplifier#Super Alpha)`}<br parentName="p"></br>{`
`}{`[Sziklai Pair]`}{`(/articles/the-transistor-amplifier#THE DARLINGTON)`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#Latch"
      }}>{`Thyristor`}</a>{` (scr) made with transistors`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#Time"
      }}>{`Time Delay`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#TotemPole"
      }}>{`Totem Pole Stage`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#Transformer"
      }}>{`Transformer`}</a>{` - adding a transformer`}<br parentName="p"></br>{`
`}{`[Transistor as a LOAD]`}{`(/articles/the-transistor-amplifier/page-2#Transistor LOAD)`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#VariableResistor"
      }}>{`Transistor as a Variable Resistor`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier#TransistorBias"
      }}>{`Transistor Bias`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#Tr-to-Relay"
      }}>{`Transistor Replaces Relay`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier#Tr-Sub"
      }}>{`Transistor Substitution`}</a><br parentName="p"></br>{`
`}{`[Transistor Tester]`}{`(/articles/the-transistor-amplifier/page-2#Transistor Tester)`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#InternalResistor"
      }}>{`Transistors with Internal Resistors`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier#Tri-State"
      }}>{`Tri-State`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#TwinT-Osc"
      }}>{`Twin T Oscillator`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#UJT"
      }}>{`UniJunction Transistor`}</a><br parentName="p"></br>{`
`}{`Voice Operated Switch - see VOX`}<br parentName="p"></br>{`
`}{`[Voltage Amplifier Circuit]`}{`(/articles/the-transistor-amplifier#Voltage Amplifier)`}<br parentName="p"></br>{`
`}{`[Voltage Buffer Circuit]`}{`(/articles/the-transistor-amplifier#Voltage Buffer)`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "#VoltageDivider"
      }}>{`Voltage Divider`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#Pump"
      }}>{`Voltage Doubler`}</a>{` - the`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#V-to-Current"
      }}>{`Voltage to Current Converter`}</a><br parentName="p"></br>{`
`}{`[Voltage Regulator]`}{`(/articles/the-transistor-amplifier/page-2#Voltage Regulator)`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier#Voltages"
      }}>{`Voltages`}</a>{` - measuring Voltages`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#VOX"
      }}>{`VOX`}</a>{` - Voice Operated Switch`}<br parentName="p"></br>{`
`}{`[Zener Tester]`}{`(/articles/the-transistor-amplifier/page-2#Zener Tester) - `}<a parentName="p" {...{
        "href": "/articles/how-a-diode-works"
      }}>{`How the ZENER DIODE works`}</a><br parentName="p"></br>{`
`}{`[Zener]`}{`(/articles/the-transistor-amplifier/page-2#Transistor Zener) The transistor as a zener Regulator`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#1wattLED"
      }}>{`1 watt LED`}</a>{` - driving a high-power LED`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier#8R-Spkr"
      }}>{`8R speaker Vs 50R Speaker`}</a><br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "/articles/the-transistor-amplifier/page-2#Flyback"
      }}>{`12v CFL Driver`}</a>{` - Flyback Circuit`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      