import React from 'react'
import { Box, Text } from 'theme-ui'
const styles = {
  price: {
    mt: 3,
    mb: 3,
    color: `alpha`,
    textAlign: `right`
  }
}

const ProductPrice = ({ price_aud, price_usd }) => (
  <Box>
    {price_aud ? (
      <Text variant='h4' sx={styles.price}>
        AU$ {price_aud}
      </Text>
    ) : null}
    {price_usd ? (
      <Text variant='h4' sx={styles.price}>
        USD$ {price_usd}
      </Text>
    ) : null}
  </Box>
)

export default ProductPrice
