import React from 'react'
import { Box, css } from 'theme-ui'
import { GatsbyImage } from 'gatsby-plugin-image'
import rv from '@components/utils/buildResponsiveVariant'
import { Link } from 'gatsby'

const ProductMedia = ({ imageObject, variant, name, slug }) =>
  imageObject?.extension.localeCompare('gif') !== 0 ? (
    <Box as={Link} to={slug}>
      <GatsbyImage
        image={imageObject?.childrenImageSharp[0].gatsbyImageData}
        alt={name}
        css={css({
          height: `full`,
          verticalAlign: `middle`,
          img: {
            bg: `omegaLighter`
          },
          variant: rv(variant, 'image')
        })}
      />
    </Box>
  ) : (
    <img src={imageObject.publicURL} alt={name} />
  )

export default ProductMedia
