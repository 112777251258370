import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Multi Chip Programmer",
  "slug": "/articles/_bec-pic-program-list",
  "author": "Colin Mitchell",
  "private": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/how-to-order"
        }}>{`Ordering Items`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "cont02-BEC_Theory.html"
        }}>{`BEC Theory`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "cont03-PIC_Theory.html"
        }}>{`PIC Theory`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "cont04-Programs.html"
        }}>{`PROGRAMS`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/pic-projects"
        }}>{`PIC Projects`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      