import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Contents Index",
  "slug": "/articles/chip-data-ebook-1d/_table-of-contents",
  "private": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/chip-data-ebook-1d/cd-4001"
        }}>{`CD 4001`}</a>{` Quad NOR gate`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/chip-data-ebook-1d/cd-4011"
        }}>{`CD 4011`}</a>{` Quad NAND gate`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/chip-data-ebook-1d/cd-4013"
        }}>{`CD 4013`}</a>{` Dual D Flip Flop`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/chip-data-ebook-1d/cd-4014"
        }}>{`CD 4014`}</a>{` 8-Stage shift register`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/chip-data-ebook-1d/cd-4017"
        }}>{`CD 4017`}</a>{` Divide by 10`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/chip-data-ebook-1d/cd-4020"
        }}>{`CD 4020`}</a>{` 14-Stage Bin Counter`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/chip-data-ebook-1d/cd-4024"
        }}>{`CD 4024`}</a>{` 7-Stage Binary Count`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/chip-data-ebook-1d/cd-4026"
        }}>{`CD 4026`}</a>{` 7-Segment Driver`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/chip-data-ebook-1d/cd-4040"
        }}>{`CD 4040`}</a>{` 12-Stage Bin Counter`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/chip-data-ebook-1d/4049ub"
        }}>{`CD 4049`}</a>{` HEX Inverter`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/chip-data-ebook-1d/cd-4060"
        }}>{`CD 4060`}</a>{` 14-Stage with Osc`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/chip-data-ebook-1d/4069ub"
        }}>{`CD 4069`}</a>{` HEX Inverter`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/chip-data-ebook-1d/74c14-40106"
        }}>{`CD 40106`}</a>{` Hex Schmitt Trigger`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/chip-data-ebook-1d/cd-4510"
        }}>{`CD 4510`}</a>{` BCD up/down Counter`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/chip-data-ebook-1d/cd-4511"
        }}>{`CD 4511`}</a>{` BCD TO 7-Segment`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/chip-data-ebook-1d/lm-324"
        }}>{`LM 324`}</a>{` Quad OP-AMP`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/chip-data-ebook-1d/lm-380"
        }}>{`LM 380`}</a>{` 4 watt amp`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/chip-data-ebook-1d/lm-386"
        }}>{`LM 386`}</a>{` 0.5 watt amp`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/chip-data-ebook-1d/lm-741"
        }}>{`LM 741`}</a>{` OP-AMP`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/chip-data-ebook-1d/op-amp-list"
        }}>{`OP-AMP List`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/chip-data-ebook-1d/opto-couplers"
        }}>{`Opto Coupler List`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/chip-data-ebook-1d/pic-chip-list"
        }}>{`PIC Chip List`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/chip-data-ebook-1d/surface-mount-diodes"
        }}>{`S/mount Diodes/Zeners`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/chip-data-ebook-1d/surface-mount-leds"
        }}>{`Surface Mount LEDs`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/chip-data-ebook-1d/surface-mount-resistors"
        }}>{`Surface Mount Resistors`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/chip-data-ebook-1d/surface-mount-transistors"
        }}>{`Surface Mount Transistors`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/chip-data-ebook-1d/surface-mount-outlines"
        }}>{`Surface Mount Outlines`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/chip-data-ebook-1d/voltage-regulators"
        }}>{`Voltage Regulators`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/chip-data-ebook-1d/74c14-40106"
        }}>{`40106`}</a>{` Hex Schmitt Trigger`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/chip-data-ebook-1d/555-timer"
        }}>{`555`}</a>{` Timer Chip`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/chip-data-ebook-1d/556-dual-timer"
        }}>{`556`}</a>{` Dual Timer Chip`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/chip-data-ebook-1d/567-tone-decoder"
        }}>{`567`}</a>{` Tone Decoder`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/articles/chip-data-ebook-1d/74c14-40106"
        }}>{`74c14`}</a>{` Hex Schmitt Trigger`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      